import { NavBar } from "react-vant";
import { Helmet } from "react-helmet";
import { useRequest, useDebounceFn } from "ahooks";
import clsx from "clsx";
import dayjs from "dayjs";

import { useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import NiceModal from "@ebay/nice-modal-react";
import ArrowUp from "@/assets/icons/arrow_up.svg?react";
import ArrowDown from "@/assets/icons/arrow_down.svg?react";
import { RoomService } from "$/api_out/http_resv/room/room_srv";
import ErrorPageIcon from "@/assets/icons/error_page.svg?react";
import {
  CustomSkeleton,
  RoomSwiper,
  FixedBottomWrapper,
  ErrorComp,
  FixedHeaderWrapper,
  PriceCard,
  Card,
  Loading,
  RentTag,
  PriceDetailPopup,
} from "@/components";
import { PATHS } from "@/constants/path";
import { genPageTitle } from "@/utils";
import { getMoneyText } from "@/utils/money";
import { useCustomNavigate, useResvInfo } from "@/hooks";
import { useIsPC } from "@/stores";
import {
  BookingCard,
  CheckOutPolicyCard,
  RoomPresentCard,
  TitleCard,
  TrafficMethodCard,
} from "./components";
import { type CostDetail } from "$/api_out/http_resv/room/room_type";

export interface RoomSearchState {
  startDate?: string;
  endDate?: string;
  adultCount: number;
}

export const RoomDetail = () => {
  const params = useParams<{ id?: string }>();
  const id = params.id;
  const location = useLocation();
  const customNavigate = useCustomNavigate();
  const isPC = useIsPC();

  const { occupancy, adultCount } = useResvInfo();

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [priceDetailRender, setPriceDetailRender] = useState(false);

  const nights = useMemo(() => {
    return occupancy
      ? dayjs(occupancy.endDate).diff(occupancy.startDate, "day")
      : 0;
  }, [occupancy]);

  const { data, loading, error, run } = useRequest(
    async () => {
      const resp = await RoomService.Get({
        id: id!,
        priceQuery: {
          startDate: occupancy?.startDate,
          endDate: occupancy?.endDate,
          adultCount,
        },
      });
      setIsFirstRender(false);

      return {
        ...resp.room,
        images: resp.room.images.map((i, index) => ({ ...i, index })),
      };
    },
    {
      refreshDeps: [occupancy],
    },
  );

  const { run: runDebounce } = useDebounceFn(run, { wait: 300 });

  const goToOrderConfirm = () => {
    if (!occupancy || !data?.canBook) {
      return;
    }

    void hidePriceDetailPopup();
    customNavigate(PATHS.ORDER_CONFIRM, {
      id,
    });
  };

  const goBack = () => {
    const path = location.state?.backPath || PATHS.ROOM_SEARCH;
    customNavigate(path as string, {
      id: location.state?.orderId,
    });
  };

  const showPriceDetailPopup = async (cost: CostDetail) => {
    setPriceDetailRender(true);
    await NiceModal.show(PriceDetailPopup, {
      cost,
      callBack: setPriceDetailRender,
    });
  };

  const hidePriceDetailPopup = async () => {
    setPriceDetailRender(false);
    await NiceModal.hide(PriceDetailPopup);
  };

  if (error) {
    return <ErrorComp error={error} />;
  }

  return (
    <div className="h-full overflow-y-auto">
      <Helmet>
        <title>{genPageTitle("房间详情")}</title>
      </Helmet>

      {isPC ? (
        <FixedHeaderWrapper pageName="房间详情" goBack={goBack} />
      ) : (
        <NavBar
          title={data?.room.title || "房间详情"}
          leftText="返回"
          placeholder={true}
          fixed={true}
          zIndex={50}
          onClickLeft={goBack}
        />
      )}

      {loading && isFirstRender ? (
        <Loading />
      ) : (
        data && (
          <>
            <div className="relative">
              {Boolean(!data?.room.isOnSale) && (
                <div className="absolute left-1/2 top-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2  items-center justify-center rounded-3xl bg-black/40 px-6 py-2 font-normal  text-white">
                  该房间已下架
                </div>
              )}
              <RoomSwiper value={data.images} />
            </div>

            <div
              className={clsx(
                isPC ? "w-[80vw] min-w-[720px]" : "px-4 pb-56",
                "mx-auto max-w-screen-lg  py-3",
              )}
            >
              <TitleCard data={data} />
              {isPC ? (
                <div className="flex space-x-4">
                  <div className="flex flex-1 flex-col space-y-4">
                    <TrafficMethodCard data={data} />
                    <RoomPresentCard data={data} />
                    <CheckOutPolicyCard data={data} />
                    {!data.room.address &&
                      !data.room.traffic &&
                      !data?.introduction &&
                      !data?.policy && (
                        <Card className="flex h-[420px] flex-col items-center justify-center gap-4">
                          <ErrorPageIcon className="size-40" />
                          <span className="text-sm text-[#979BAD]">
                            暂无房间更多信息
                          </span>
                        </Card>
                      )}
                  </div>
                  <div className="w-[360px]">
                    <BookingCard
                      data={data}
                      roomId={id}
                      changeAdultCb={runDebounce}
                      maxAdultCount={data.room.personCapacity}
                      className="sticky top-20"
                    >
                      {!data.cost || (occupancy && !data.canBook) ? null : (
                        <PriceCard priceData={data} loading={loading} />
                      )}
                      <button
                        type="button"
                        className={clsx(
                          "mt-8 w-full rounded-full px-16 py-3 text-base font-medium text-white",
                          occupancy && !loading && data.canBook
                            ? "hover:bg-text-333 bg-[#3D3D3D]"
                            : "bg-[#979BAD]",
                        )}
                        onClick={goToOrderConfirm}
                      >
                        预定
                      </button>
                    </BookingCard>
                  </div>
                </div>
              ) : (
                <div className="space-y-3">
                  <BookingCard
                    data={data}
                    roomId={id}
                    maxAdultCount={data.room.personCapacity}
                    changeAdultCb={runDebounce}
                  />
                  <TrafficMethodCard data={data} />
                  <RoomPresentCard data={data} />
                  <CheckOutPolicyCard data={data} />
                </div>
              )}
            </div>

            {/* 根据是canBook字符 和 isOnSale字符   判断是否可预定 */}
            {data.room.isOnSale && !isPC && (
              <FixedBottomWrapper className="flex h-20 w-full items-center justify-between border-t border-[#E9F0FF] bg-white px-6 py-3">
                {occupancy && !data.canBook && (
                  <span className="mr-2 text-xs text-[#EC642B]">
                    已选日期不可定,请重新选择
                  </span>
                )}
                {data.lowestPrice && !occupancy && (
                  <div className="flex flex-row items-center">
                    <CustomSkeleton
                      row={1}
                      rowWidth={148}
                      rowHeight={30}
                      loading={loading}
                      style={{ padding: 0 }}
                      $color="#FFEFF2"
                    >
                      <span className="text-danger mr-1 text-xl font-medium">
                        {getMoneyText(
                          data.lowestPrice.amount,
                          data.lowestPrice.currency,
                          { hidePlusSign: true },
                        )}
                      </span>
                      <span className="text-text-2 flex flex-col items-center space-x-2 text-xs">
                        起/晚
                      </span>
                    </CustomSkeleton>
                  </div>
                )}

                {data.cost && data.canBook ? (
                  <div className="flex flex-col ">
                    <div className="text-danger text-xl font-medium">
                      <CustomSkeleton
                        row={1}
                        rowWidth={128}
                        rowHeight={30}
                        loading={loading}
                        style={{ marginBottom: 8, padding: 0 }}
                        $color="#FFEFF2"
                      >
                        <div className="inline-flex items-center">
                          <span>
                            {getMoneyText(
                              data.cost.payAmount,
                              data.cost.currency,
                              { hidePlusSign: true },
                            )}
                          </span>
                          <RentTag
                            discounts={data.cost.discounts}
                            className="ml-1.5"
                          />
                        </div>
                      </CustomSkeleton>
                    </div>
                    <div className="text-text-2 mb-2 flex flex-row items-start space-x-2 text-xs">
                      <CustomSkeleton
                        row={1}
                        rowWidth={64}
                        rowHeight={10}
                        loading={loading}
                        style={{ padding: 0 }}
                      >
                        <span>
                          均价
                          {getMoneyText(
                            data.cost.payAmount / nights,
                            data.cost.currency,
                            { hideLabel: true, hidePlusSign: true },
                          )}
                          /晚
                        </span>
                        <div
                          onClick={async () => {
                            priceDetailRender
                              ? await hidePriceDetailPopup()
                              : await showPriceDetailPopup(data.cost!);
                          }}
                          className="text-easbnb-brand ml-2 flex items-center"
                        >
                          <span className="mr-2">明细</span>
                          {priceDetailRender ? <ArrowDown /> : <ArrowUp />}
                        </div>
                      </CustomSkeleton>
                    </div>
                  </div>
                ) : null}

                <button
                  type="button"
                  className={clsx(
                    "rounded-full px-12 py-3 text-base font-medium text-white ",
                    occupancy && !loading && data.canBook
                      ? "hover:bg-text-333 bg-[#3D3D3D]"
                      : "bg-[#979BAD]",
                  )}
                  onClick={goToOrderConfirm}
                >
                  预定
                </button>
              </FixedBottomWrapper>
            )}
          </>
        )
      )}
    </div>
  );
};
