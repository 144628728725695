import clsx from "clsx";
import { type Discount } from "$/api_out/http_resv/room/room_type";
import { DISCOUNT_MAP } from "@/constants/options";

interface RentTagProps {
  discounts: Discount[];
  className?: string;
}
export const RentTag: React.FC<RentTagProps> = ({ discounts, className }) => {
  if (discounts.length === 0) return null;

  return (
    <div className="flex items-center ">
      {discounts.map((discount) => (
        <div
          key={discount.kind}
          className={clsx(
            "flex h-5 items-center justify-center rounded bg-[#F74D36] p-1 text-[10px] font-normal text-white",
            className,
          )}
        >
          {DISCOUNT_MAP[discount.kind].label}
        </div>
      ))}
    </div>
  );
};
