/** @format */

// This is code generated automatically by the proto2api, please do not modify

import {
  type ContentKind,
  type Color,
  type EntityKind,
  type Currency,
} from "../const/const_enum";

export interface Remark {
  // 实体信息
  entity: EntityInfo;
  // 内容类型
  contentKind: ContentKind;
  // 内容
  content: string;
  // 预览内容
  preview: string;
  // 创建/修改 日期 @uint64
  updatedAt: string;
  // 创建者
  creatorUserId: string;
}

export interface RemarkContent {
  // 备注内容
  content: string;
  // 内容类型
  contentKind: ContentKind;
}

export interface RemarkPreview {
  // 预览内容
  preview: string;
  // 创建/修改 日期 @uint64
  updatedAt: string;
  // 创建者
  creatorUserId: string;
}

export interface Tag {
  // 标签id
  id: string;
  // 标签名
  name: string;
  // 标签颜色
  color: Color;
  // 对应实体
  entityKind: EntityKind;
}

export interface Image {
  entityInfo: EntityInfo;
  // 类型
  partition: PartitionKind;
  // 文件名
  name: string;
  // 真实文件名
  realName: string;
  // uri
  uri: string;
  // rank最大的为主图 房客更新的时候忽略 @uint32
  rank: number;
}

// 实体信息
export interface EntityInfo {
  // 实体
  entityKind: EntityKind;
  // 对应实体的id
  relationId: string;
  // 日期备注的情况下 subId就是年月日
  relationSubId: string;
}

export interface CurrencyAmount {
  // 结算币种
  currency: Currency;
  // 金额 @int32
  amount: number;
  // 汇率 @int32
  exchangeRate: number;
}

export interface ExchangeAmount {
  totalAmount: CurrencyAmount;
  currencyAmount: CurrencyAmount[];
}

export interface DateRange {
  // true 就是查询所有数据 @bool
  whole?: boolean;
  // true 就是查询日期等于空的数据 @bool
  empty?: boolean;
  // 开始日期
  start?: string;
  // 结束日期
  end?: string;
}

export interface TimeRange {
  //  @bool
  whole?: boolean;
  //  @bool
  empty?: boolean;
  // 开始日期 @uint64
  start?: string;
  // 结束日期 @uint64
  end?: string;
}

export interface Point {
  // 经度 @float
  lng: number;
  // 纬度 @float
  lat: number;
}
export enum PartitionKind {
  // default 默认 除房间外其他entity默认的分区
  Partition_0 = 0,
  Partition_1 = 1,
  Partition_2 = 2,
  Partition_3 = 3,
  Partition_4 = 4,
  Partition_5 = 5,
  Partition_6 = 6,
  Partition_7 = 7,
  // 自定义
  Bedroom = 4001,
  // 客厅
  Parlor = 4002,
  // 厨房
  Kitchen = 4003,
  // 卫浴
  Bathroom = 4004,
  // 外观
  Exterior = 4005,
  // 其他
  Other = 4006,
}
