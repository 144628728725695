import { useState } from "react";
import { Dialog, NavBar, Toast } from "react-vant";
import { generatePath, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useCountDown, useRequest } from "ahooks";
import { isUndefined, isEmpty } from "lodash-es";
import clsx from "clsx";
import { OrderService } from "$/api_out/http_resv/order/order_srv";
import type * as order_srv from "$/api_out/http_resv/order/order_srv";
import { type PayChannel } from "$/api_out/http_resv/org/org_type";
import { PayKind } from "$/api_out/http_pms/const/const_enum";

import {
  Loading,
  Card,
  FixedBottomWrapper,
  ErrorComp,
  FixedHeaderWrapper,
} from "@/components";
import { useIsPC, useOrgInfoStore } from "@/stores";
import { PayChannels } from "./components/PayChannels";
import { genPageTitle } from "@/utils";
import { isInWeChat } from "@/utils/environment";
import { getMoneyText } from "@/utils/money";
import { PATHS } from "@/constants/path";
import { getOrderStatus, OrderStatusEnum } from "@/utils/order";
import { useCustomNavigate } from "@/hooks";
import { makeRedirUrl } from "$/utils/urls";

export const Pay = () => {
  const customNavigate = useCustomNavigate();
  const isPC = useIsPC();

  const inWechat = isInWeChat();
  const [pay, setPay] = useState<PayChannel>();
  const orgInfo = useOrgInfoStore((s) => s.data);
  const cantPay = isEmpty(orgInfo?.org?.payChannels || []) || isEmpty(pay);
  const params = useParams<{ id?: string }>();
  const id = params.id;
  const {
    data,
    loading,
    run,
    error: orderError,
  } = useRequest(
    async () => {
      const resp = await OrderService.Get({
        id: id!,
      });
      const countDownDate = resp.order.order.isCancelled
        ? undefined
        : Math.max(
            Number(resp?.order.order.cancelledAt) * 1000,
            Date.now() + 5000,
          );

      return { ...resp.order, countDownDate };
    },
    {
      ready: !isUndefined(id),
      onSuccess: (resp) => {
        const orderStatus = getOrderStatus({
          dateStart: resp.order.startDate,
          dateEnd: resp.order.endDate,
          paidAt: resp.order.paidAt,
          isCancelled: resp.order.isCancelled,
        });
        if (orderStatus !== OrderStatusEnum.Unpaid) {
          customNavigate(PATHS.ORDER, {
            id: resp.order.id,
          });
        }
      },
    },
  );

  const [, formattedRes] = useCountDown({
    targetDate: data?.countDownDate,
    onEnd: () => {
      if (!data?.order?.isCancelled) {
        run();
      }
    },
  });

  const goToPay = async () => {
    if (cantPay) {
      return;
    }

    const noRedirect = isPC && pay.payKind === PayKind.WechatPay && !inWechat;
    const payParams: order_srv.PayReq = {
      orderId: id!,
      payChannel: pay.id,
      inWechat: inWechat || isPC,
      redirectUri: noRedirect
        ? ""
        : makeRedirUrl(
            `${window.location.origin}${generatePath(`${PATHS.PAY_RESULT}/:id`, { id: id! })}`,
          ),
    };

    const clear = Toast.loading({
      message: "支付中,请稍等...",
    });
    try {
      const resp = await OrderService.Pay(payParams);
      if (!resp.endpoint) {
        throw new Error("获取支付链接失败");
      }

      // PC端 微信支付时回生成二维码 不进行跳转 直接去结果页面
      if (noRedirect) {
        customNavigate(PATHS.PAY_RESULT, {
          id: id!,
          state: {
            endpoint: resp.endpoint,
          },
        });
      } else {
        // 打开支付链接，进行支付(放在最后的目的是，防止后续流程偶发性中断跳转)
        window.location.replace(makeRedirUrl(resp.endpoint));
      }
    } catch (e: any) {
      console.error(e);
      Toast.fail({
        message: e.message || "跳转支付链接失败",
        duration: 2000,
      });
    } finally {
      clear.clear();
    }
  };

  if (!id) {
    return (
      <div className="flex h-[50vh] w-full items-center justify-center">
        <Helmet>
          <title>{genPageTitle("支付方式")}</title>
        </Helmet>
        <div className="text-text-4 text-sm">无法获取订单支付信息</div>
      </div>
    );
  }

  if (orderError) {
    return <ErrorComp error={orderError} />;
  }

  const goBack = () => {
    Dialog.confirm({
      title: "确认放弃支付吗",
      confirmButtonText: "继续支付",
      confirmButtonColor: "var(--wd-color-easbnb-brand)",
      cancelButtonText: "放弃",
    })
      .then(() => {})
      .catch(() => {
        customNavigate(PATHS.ORDER);
      });
  };
  return (
    <div>
      {isPC ? (
        <FixedHeaderWrapper goBack={goBack} pageName="确认支付" />
      ) : (
        <NavBar
          title="确认支付"
          leftText="返回"
          placeholder={true}
          zIndex={50}
          onClickLeft={goBack}
        />
      )}

      <Helmet>
        <title>{genPageTitle("支付方式")}</title>
      </Helmet>
      {loading ? (
        <Loading />
      ) : (
        <div>
          {data && (
            <div className="h-full px-4 py-3">
              {isPC ? (
                <Card className="mx-auto mb-3 flex w-[80vw] min-w-[720px] max-w-screen-lg flex-col space-y-2 rounded-2xl p-6">
                  <div className="flex items-center justify-start">
                    <div className="mr-2 text-base text-[#2C2D39]">
                      实际支付金额:
                    </div>
                    <div className="text-danger text-2xl font-medium">
                      {getMoneyText(data.cost.payAmount, data.cost.currency, {
                        hidePlusSign: true,
                      })}
                    </div>
                  </div>
                  <div className="text-text-2 text-sm">
                    支付剩余{formattedRes.minutes.toString().padStart(2, "0")}分
                    {formattedRes.seconds.toString().padStart(2, "0")}
                    秒，超时将自动取消订单
                  </div>
                </Card>
              ) : (
                <Card className="mb-3 flex flex-col items-center space-y-4 rounded-2xl">
                  <div className="text-text-2 text-sm">实付金额</div>
                  <div className="text-danger text-2xl font-medium">
                    {getMoneyText(data.cost.payAmount, data.cost.currency, {
                      hidePlusSign: true,
                    })}
                  </div>
                  <div className="text-text-2 pb-5 text-sm">
                    剩余支付时间
                    {formattedRes.minutes.toString().padStart(2, "0")}分
                    {formattedRes.seconds.toString().padStart(2, "0")}秒
                  </div>
                </Card>
              )}

              <PayChannels
                payChannels={orgInfo?.org?.payChannels || []}
                value={pay}
                onChange={setPay}
                cantPay={cantPay}
                pay={goToPay}
              />

              {!isPC && (
                <FixedBottomWrapper className="flex w-full items-center justify-center bg-white py-3">
                  <button
                    type="button"
                    className={clsx(
                      "rounded-full px-20 py-3 text-base font-medium text-white",
                      cantPay ? "bg-text-2" : "hover:bg-text-333 bg-[#3D3D3D]",
                    )}
                    onClick={goToPay}
                  >
                    确认支付
                  </button>
                </FixedBottomWrapper>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
