import NiceModal from "@ebay/nice-modal-react";
import { Card, CustomSkeleton, PriceDetailPopup, RentTag } from "@/components";
import { getMoneyText } from "@/utils/money";
import ArrowRight from "@/assets/icons/arrow_right.svg?react";
import { type RoomInDetail } from "$/api_out/http_resv/room/room_type";

interface PriceCardCardProps {
  priceData: RoomInDetail;
  loading?: boolean;
}

export const PriceCard: React.FC<PriceCardCardProps> = ({
  priceData,
  loading = false,
}) => {
  return (
    <Card className="p-0">
      <div className="bg-page-bg mt-6 flex flex-col items-center justify-between rounded-xl p-4">
        {priceData.cost?.payAmount && priceData.canBook ? (
          <div className="flex w-full items-center justify-between border-b border-[#D2DDF8] pb-2">
            <span className="text-text-666 text-sm">订单金额</span>
            <CustomSkeleton
              row={1}
              rowWidth={148}
              rowHeight={32}
              loading={loading}
              style={{ padding: 0 }}
              $color="#FFEFF2"
            >
              <div className="inline-flex items-center">
                <span className="text-danger text-2xl font-medium">
                  {getMoneyText(
                    priceData.cost.payAmount,
                    priceData.cost.currency,
                    {
                      hidePlusSign: true,
                    },
                  )}
                </span>
                <RentTag
                  discounts={priceData.cost.discounts}
                  className="ml-1.5"
                />
              </div>
            </CustomSkeleton>
          </div>
        ) : null}
        {priceData.lowestPrice ? (
          <div className="flex w-full items-center justify-between border-b border-[#D2DDF8] pb-2">
            <span className="text-text-666 text-sm">房间单价</span>
            <span className=" flex items-center">
              <span className="text-danger mr-2 text-2xl font-medium">
                {getMoneyText(
                  priceData.lowestPrice.amount,
                  priceData.lowestPrice.currency,
                  {
                    hidePlusSign: true,
                  },
                )}
              </span>
              <span className="text-text-666 text-sm">起/晚</span>
            </span>
          </div>
        ) : null}
        {priceData?.cost ? (
          <div className="text-text-666 mt-2 flex w-full flex-col space-y-3 text-sm">
            {priceData.cost.payAmount < priceData.cost.fullAmount ? (
              <div className="flex justify-between">
                <span>总金额</span>
                <CustomSkeleton
                  row={1}
                  rowWidth={85}
                  rowHeight={16}
                  loading={loading}
                  style={{ padding: 0 }}
                >
                  <span className="line-through">
                    {getMoneyText(
                      priceData.cost.fullAmount,
                      priceData.cost.currency,
                      {
                        hideLabel: true,
                        hidePlusSign: true,
                      },
                    )}
                  </span>
                </CustomSkeleton>
              </div>
            ) : null}
            {priceData.cost.cleaningFee ? (
              <div className="flex justify-between">
                <span>清扫费</span>
                <CustomSkeleton
                  row={1}
                  rowWidth={85}
                  rowHeight={16}
                  style={{ padding: 0 }}
                  loading={loading}
                >
                  <span>
                    {getMoneyText(
                      priceData.cost.cleaningFee,
                      priceData.cost.currency,
                      {
                        hideLabel: true,
                        hidePlusSign: true,
                      },
                    )}
                  </span>
                </CustomSkeleton>
              </div>
            ) : null}
            {priceData.cost.extraPersonTotalAmount ? (
              <div className="flex justify-between">
                <span>额外人头费</span>
                <CustomSkeleton
                  row={1}
                  rowWidth={85}
                  rowHeight={16}
                  style={{ padding: 0 }}
                  loading={loading}
                >
                  <span>
                    {getMoneyText(
                      priceData.cost.extraPersonTotalAmount,
                      priceData.cost.currency,
                      {
                        hideLabel: true,
                        hidePlusSign: true,
                      },
                    )}
                  </span>
                </CustomSkeleton>
              </div>
            ) : null}
          </div>
        ) : null}
        {loading || priceData.lowestPrice ? null : (
          <div
            onClick={async () => {
              await NiceModal.show(PriceDetailPopup, {
                cost: priceData.cost,
              });
            }}
            className="text-easbnb-brand mt-2 flex cursor-pointer items-center text-xs"
          >
            <span className="mr-2">费用明细</span>
            <ArrowRight />
          </div>
        )}
      </div>
    </Card>
  );
};
