import React from "react";
import { Image } from "react-vant";
import clsx from "clsx";
import LocationIcon from "@/assets/icons/location.svg?react";
import { Card } from "@/components/Card";
import { PLACEHOLDER_TEXT } from "@/constants/text";
import { getMoneyText } from "@/utils/money";
import { getRoomInfo } from "@/utils/roomInfo";
import { PATHS } from "@/constants/path";
import { useCustomNavigate } from "@/hooks";
import { useIsPC } from "@/stores";
import { type OrderInList } from "$/api_out/http_resv/order/order_type";

interface RoomCardProps {
  extra?: React.ReactNode;
  header?: React.ReactNode;
  disableLink?: boolean;
  orderId?: string;
  onClick?: () => void;
  data: Partial<OrderInList> & { room: OrderInList["room"] };
  imageUri: string;
}

export const RoomCardHeaderWrapper = ({
  children,
}: React.PropsWithChildren) => {
  return (
    <div className="flex justify-between border-b border-dashed border-b-[#e5e5e55] pb-2">
      {children}
    </div>
  );
};

export const RoomCard: React.FC<RoomCardProps> = ({
  extra,
  header,
  disableLink = false,
  data,
  orderId,
  imageUri,
  ...props
}) => {
  const roomInfoText = getRoomInfo({
    room: data.room,
  });
  const customNavigate = useCustomNavigate();
  const isPC = useIsPC();

  const onGoToRoomDetail = (e: React.MouseEvent) => {
    if (disableLink) return;
    customNavigate(PATHS.ROOM, {
      id: data.room.id,
      state: {
        backPath: PATHS.ORDER,
        orderId,
      },
    });

    // 在订单列表页点击列表项中的房间卡片，会触发房间详情页的跳转
    // 在订单列表页点击列表项，会触发订单详情页的跳转
    // 此时需要阻止事件冒泡，防止点击房间卡片的时候触发订单详情页的跳转
    e.stopPropagation();
  };

  return (
    <Card
      className={clsx(
        isPC
          ? "w-[80vw] min-w-[720px] max-w-screen-lg px-6 hover:shadow-[0px_4px_24px_0px_#272C3E0F]"
          : "w-full",
        "mx-auto ",
      )}
      {...props}
    >
      {header}

      <div className="flex cursor-pointer py-3" onClick={onGoToRoomDetail}>
        <div
          className={clsx(
            isPC ? "size-[120px]" : "size-[84px]",
            "mr-3 overflow-hidden rounded-lg",
          )}
        >
          <Image src={imageUri} alt="房间图片" fit="cover" />
        </div>
        <div className="flex flex-1 flex-col ">
          <div className="text-text-1 mb-2 line-clamp-1 text-ellipsis text-base font-medium">
            {data.room.title || PLACEHOLDER_TEXT}
          </div>
          {data.room.roomSize ? (
            <div className="mb-2 flex">
              <span className="text-text-666 text-sm">{roomInfoText}</span>
            </div>
          ) : null}
          {data.payAmount && data.currency ? (
            <>
              {data.payAmount ? (
                <span className="text-text-2 text-sm">
                  {getMoneyText(data.payAmount, data.currency, {
                    hidePlusSign: true,
                  })}
                  {data.refundAmount ? (
                    <span className="ml-2">
                      (退款:
                      {getMoneyText(data.refundAmount, data.currency, {
                        hidePlusSign: true,
                        hideLabel: true,
                      })}
                      )
                    </span>
                  ) : null}
                </span>
              ) : null}
            </>
          ) : (
            <>
              {data.room.personCapacity ? (
                <span className="text-easbnb-brand text-sm">
                  可住{data.room.personCapacity}人
                </span>
              ) : null}
            </>
          )}
          {isPC && <CardLocation address={data.room.address} extra={extra} />}
        </div>
      </div>

      {!isPC && data.room.address && (
        <div className="flex items-center space-x-2 border-t border-dashed border-t-[#e5e5e55] pt-3.5">
          <LocationIcon className="size-4" />
          <div className="text-text-666 text-sm">{data.room.address}</div>
        </div>
      )}

      {!isPC && extra}
    </Card>
  );
};

const CardLocation: React.FC<{
  address?: string;
  extra?: React.ReactNode;
}> = ({ address, extra }) => {
  const isPC = useIsPC();

  return (
    <div
      className={clsx(
        "flex items-center justify-between",
        isPC ? "mt-4" : "mt-8",
      )}
    >
      {address ? (
        <div className="flex items-center space-x-2">
          <LocationIcon className="size-4" />
          <div className="text-text-666 text-sm">{address}</div>
        </div>
      ) : (
        <i />
      )}
      {extra}
    </div>
  );
};
