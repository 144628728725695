import { create } from "zustand";
import type * as sign_srv from "$/api_out/http_resv/sign/sign_srv";
import { SignService } from "$/api_out/http_resv/sign/sign_srv";

interface State {
  userInfo?: sign_srv.LoginResp["user"] | null;
  defaultTenant?: sign_srv.LoginResp["defaultTenant"] | null;
  autoLoginInfo?: {
    autoLoginKind: number;
    autoLoginCode: string;
  };
}

interface Action {
  setUserInfo: (userInfo: sign_srv.LoginResp["user"]) => void;
  removeUserInfo: () => void;
  fetchUserInfo: () => Promise<sign_srv.LoginResp | unknown>;
  setAutoLogin: (autoLoginKind: number, autoLoginCode: string) => void;
}

export const useUserInfoStore = create<State & Action>((set, get) => ({
  setUserInfo: (userInfo) => set({ userInfo }),
  removeUserInfo: () => set({ userInfo: null }),
  fetchUserInfo: async () => {
    try {
      const resp = await SignService.Login({ ...get().autoLoginInfo! });
      set({ userInfo: resp.user, defaultTenant: resp.defaultTenant });
      return resp;
    } catch (error) {
      return error;
    }
  },
  setAutoLogin: (autoLoginKind, autoLoginCode) => {
    set({ autoLoginInfo: { autoLoginKind, autoLoginCode } });
  },
}));
