import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Popup } from "react-vant";
import clsx from "clsx";
import { createGlobalStyle } from "styled-components";
import { first, last } from "lodash-es";
import dayjs from "dayjs";
import { useIsPC } from "@/stores";
import { getMoneyText } from "@/utils/money";
import QuestionIcon from "@/assets/icons/question.svg?react";
import { type CostDetail } from "$/api_out/http_resv/room/room_type";
import { getWeekDay, toMMDD } from "@/lib/utils";
import { DISCOUNT_MAP } from "@/constants/options";

const OffsetStyle = createGlobalStyle`
  .rv-popup {
    bottom: 80px;
  }
  .rv-overlay {
    top: -90px; 
  }
`;

export const PriceDetailPopup = NiceModal.create(
  ({
    cost,
    callBack,
  }: {
    cost: CostDetail;
    callBack?: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    const modal = useModal();
    const isPC = useIsPC();

    return (
      <Popup
        visible={modal.visible}
        closeable={isPC}
        className={clsx(
          isPC ? " max-h-[640px] w-[480px]" : "h-[calc(80%-80px)]",
        )}
        position={isPC ? "center" : "bottom"}
        round={true}
        destroyOnClose={true}
        onClose={async () => {
          callBack?.(false);
          await modal.hide();
        }}
        onClosed={() => {
          modal.remove();
        }}
      >
        {!isPC && <OffsetStyle />}

        <div className={clsx("flex h-full flex-col bg-white px-4")}>
          <div className="mx-4 mt-4 flex justify-center  pb-2 text-lg font-medium">
            费用明细
          </div>
          <div className="flex h-full flex-col overflow-y-auto border-t border-[#E9F0FF]">
            <PayAmount data={cost} />
            <div className="border-y border-[#E9F0FF] py-3">
              <div className="mb-2 flex items-center justify-between text-sm">
                <span className="text-text-1">
                  房费
                  <span className="text-text-666 ml-2 text-xs">
                    {first(cost.dailyAmounts)?.date} ~
                    {last(cost.dailyAmounts)?.date} (共
                    {cost.dailyAmounts.length}
                    晚)
                  </span>
                </span>
                <span className="text-base font-medium">
                  {getMoneyText(cost.baseAmount, cost.currency, {
                    hidePlusSign: true,
                    hideLabel: true,
                  })}
                </span>
              </div>
              <div className="flex flex-wrap gap-1.5">
                {cost.dailyAmounts.map((item) => (
                  <div
                    className={clsx(
                      "flex h-11  flex-col justify-between rounded-md bg-[#F5F7F9] px-2 py-1",
                      isPC ? "w-[calc(20%-5px)]" : " w-[calc(25%-5px)]",
                    )}
                    key={item.date}
                  >
                    <div className="text-text-666 flex justify-between text-xs">
                      <span>{toMMDD(item.date)}</span>
                      <span>{getWeekDay(dayjs(item.date))}</span>
                    </div>
                    <div className="text-text-333 text-xs font-medium">
                      {getMoneyText(item.amount, cost.currency, {
                        hidePlusSign: true,
                        hideLabel: true,
                      })}
                    </div>
                  </div>
                ))}
              </div>
              {cost.extraPersonTotalAmount ? (
                <div className="mb-2 mt-4 flex items-center justify-between text-sm">
                  <span className="text-text-1">额外人头费</span>
                  <span className="text-base font-medium">
                    {getMoneyText(cost.extraPersonTotalAmount, cost.currency, {
                      hidePlusSign: true,
                      hideLabel: true,
                    })}
                  </span>
                </div>
              ) : null}

              {cost.extraPersonPerAmount && cost.extraPersonTotalAmount ? (
                <div className="text-text-333  flex items-center justify-between text-sm">
                  <span>
                    {cost.freePersonCount ? (
                      <span>
                        标准{cost.freePersonCount}人入住，第
                        {cost.freePersonCount + 1}
                        人起
                      </span>
                    ) : null}
                    {getMoneyText(cost.extraPersonPerAmount, cost.currency, {
                      hidePlusSign: true,
                      hideLabel: true,
                    })}
                    /人/晚
                  </span>
                  <span>
                    {getMoneyText(cost.extraPersonTotalAmount, cost.currency, {
                      hidePlusSign: true,
                      hideLabel: true,
                    })}
                  </span>
                </div>
              ) : null}
              {cost.discountAmount ? (
                <div className="mb-2 mt-4 flex items-center justify-between text-sm">
                  <span className="text-text-1">享受优惠</span>
                  <span className="text-base font-medium">
                    -
                    {getMoneyText(cost.discountAmount, cost.currency, {
                      hidePlusSign: true,
                      hideLabel: true,
                    })}
                  </span>
                </div>
              ) : null}
              {cost.discounts?.length > 0
                ? cost.discounts.map((item) => (
                    <div
                      key={item.kind}
                      className="flex items-center justify-between text-sm text-[#F74D36]"
                    >
                      <span>
                        {DISCOUNT_MAP[item.kind].label}
                        优惠
                      </span>
                      <span>-{(item.percentage || 0) / 100}%</span>
                    </div>
                  ))
                : null}
            </div>
            {cost.cleaningFee ? (
              <div className="py-3">
                <div className="relative mb-2 flex items-center justify-between text-sm">
                  <div className="flex items-center ">
                    <span className=" mr-2">附加费用</span>
                    <div className="group cursor-pointer">
                      <QuestionIcon />
                      <div className="text-text-333 absolute bottom-6 left-0 hidden rounded-md bg-white px-2 py-1 text-xs shadow-md group-hover:block">
                        附加费用不计入优惠金额
                      </div>
                    </div>
                  </div>
                  <span className="text-base font-medium">
                    {getMoneyText(cost.cleaningFee, cost.currency, {
                      hidePlusSign: true,
                      hideLabel: true,
                    })}
                  </span>
                </div>
                <div className="text-text-333  flex items-center justify-between text-sm">
                  <span>清扫费</span>
                  <span>
                    {getMoneyText(cost.cleaningFee, cost.currency, {
                      hidePlusSign: true,
                      hideLabel: true,
                    })}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </Popup>
    );
  },
);

const PayAmount = ({ data }: { data: CostDetail }) => {
  return (
    <>
      <div className="flex items-center justify-between py-3">
        <span className="text-text-1 text-base">支付金额</span>
        <span className="text-danger text-xl font-medium">
          {getMoneyText(data.payAmount, data.currency, {
            hidePlusSign: true,
          })}
        </span>
      </div>
      {data.refundAmount ? (
        <div className="mb-3 flex flex-col justify-between gap-2 rounded-md bg-[#F5F7F9] p-3">
          <div className=" flex items-center justify-between text-sm">
            <span className="text-text-333">退款</span>
            <span className="font-medium">
              {getMoneyText(data.refundAmount, data.currency, {
                hidePlusSign: true,
                hideLabel: true,
              })}
            </span>
          </div>
          {data.refundRecords?.map((item) => (
            <div
              key={item.refundAt}
              className="text-text-333 flex items-center justify-between text-sm"
            >
              <span>
                {dayjs.unix(Number(item.refundAt)).format("YYYY-MM-DD HH:mm")}
              </span>
              <span>
                {getMoneyText(item.refundAmount, data.currency, {
                  hidePlusSign: true,
                  hideLabel: true,
                })}
              </span>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};
