import ReactDOM from "react-dom/client";
import { Toast } from "react-vant";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { AutoLoginKind } from "$/api_out/http_resv/sign/sign_srv.ts";
import App from "./pages/App.tsx";
import "./index.css";
import { useUserInfoStore } from "./stores/useUserInfoStore.ts";
import { isInWeChat } from "./utils/environment.ts";
import { ErrorBoundary } from "./components/index.ts";
import { subscribeApiErr } from "$/utils/webapi.ts";
import { makeRedirUrl } from "$/utils/urls.ts";

dayjs.extend(utc);
dayjs.extend(timezone);

subscribeApiErr((err) => {
  if (err.status === 401) {
    useUserInfoStore.getState().removeUserInfo();
  } else {
    Toast.fail(err.message);
  }
});

const searchParams = new URLSearchParams(window.location.search);
const autoLoginKind = searchParams.get("autoLoginKind");
const autoLoginCode = searchParams.get("code");

if (autoLoginKind && autoLoginCode) {
  useUserInfoStore
    .getState()
    .setAutoLogin(Number(autoLoginKind), autoLoginCode);
} else if (isInWeChat()) {
  const APPID = "wx797bf7691b830970";
  const redirURL = new URL(window.location.href);
  redirURL.searchParams.set("autoLoginKind", AutoLoginKind.Wechat.toString());

  const refreshUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${APPID}&redirect_uri=${makeRedirUrl(redirURL.toString())}&response_type=code&scope=snsapi_base#wechat_redirect`;
  window.location.replace(refreshUrl);
  throw new Error("goto wx login");
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
);
