import { useRef, useState } from "react";
import { Image, NavBar } from "react-vant";
import { Helmet } from "react-helmet";
import { isEmpty } from "lodash-es";
import NiceModal from "@ebay/nice-modal-react";
import { useEventListener, useInfiniteScroll } from "ahooks";
import clsx from "clsx";
import type * as room_type from "$/api_out/http_resv/room/room_type";
import type * as room_srv from "$/api_out/http_resv/room/room_srv";
import { RoomService } from "$/api_out/http_resv/room/room_srv";
import type * as OrgConfig from "$/api_out/http_resv/org/org_type";
import { type Point } from "$/api_out/http_resv/common/common_type";
import SearchIcon from "@/assets/icons/search.svg?react";
import CancelIcon from "@/assets/icons/cancel.svg?react";
import EmptySearch from "@/assets/images/empty_search.svg?react";
import LocationIcon from "@/assets/icons/location.svg?react";
import SubwayIcon from "@/assets/icons/subway.svg?react";
import Distance from "@/assets/icons/distance.svg?react";
import { genPageTitle } from "@/utils";
import { getRoomInfo, getDistanceInfo } from "@/utils/roomInfo";
import { toYYYYMMDD } from "@/lib/utils";
import { HeaderLodgingDates } from "./components";
import {
  SearchPopup,
  DateRangePopup,
  type DateRangePopupProps,
  Loading,
  ErrorComp,
  FixedHeaderWrapper,
  Card,
  type DateRange,
} from "@/components";
import NoPhoto from "@/assets/icons/no_photo.svg?react";
import { useCustomNavigate, useResvInfo } from "@/hooks";
import { PATHS } from "@/constants/path";
import { getMoneyText } from "@/utils/money";
import { genSmallRoomCoverUrl } from "@/utils/file";
import { useIsPC } from "@/stores";
import { RentDiscount } from "./components/RentDiscount";

export const RoomList = () => {
  const isPC = useIsPC();

  // const [queryCurrent, setQueryCurrent] = useState(1);
  const queryCurrent = useRef(1);
  const customNavigate = useCustomNavigate();
  const [isLarge, setIsLarge] = useState(() => {
    return window.innerWidth > 1024;
  });

  useEventListener("resize", () => {
    setIsLarge(window.innerWidth > 1024);
  });
  const ref = useRef<HTMLDivElement>(null);

  const { address, setAddress, occupancy, setOccupancy, adultCount } =
    useResvInfo();

  const {
    data: listData,
    loading,
    noMore,
    error,
  } = useInfiniteScroll(
    async () => {
      const params: room_srv.ListRoomReq = {
        current: queryCurrent.current,
        pageSize: 10,
        priceQuery: {
          startDate: occupancy?.startDate,
          endDate: occupancy?.endDate,
          adultCount,
        },
      };

      if (address?.point.lat && address?.point.lng) {
        params.point = address?.point;
      }

      const resp = await RoomService.ListRoom(params);
      queryCurrent.current++;

      return {
        list: resp.rooms,
        total: resp.total,
        current: resp.current,
        pageSize: resp.pageSize,
      };
    },
    {
      target: ref,
      reloadDeps: [address, occupancy, adultCount],
      isNoMore: (data) =>
        data ? data.total <= data.current * data.pageSize : true,
    },
  );

  const showDateRangePopup = async () => {
    const params: DateRangePopupProps = {
      value: occupancy,
    };
    const res: DateRange | undefined = await NiceModal.show(
      DateRangePopup,
      params,
    );

    setOccupancy(res);
    queryCurrent.current = 1;
  };

  const showSearchPopup = async () => {
    const res: OrgConfig.PopularLocation = await NiceModal.show(
      SearchPopup,
      {},
    );
    setAddress(res);
    queryCurrent.current = 1;
  };

  const deleteAddress = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAddress(undefined);
    queryCurrent.current = 1;
  };

  const changeOccupancy = (value: DateRange | undefined) => {
    setOccupancy(value);
    queryCurrent.current = 1;
  };

  if (error) {
    return <ErrorComp error={error} />;
  }

  return (
    <div className="flex size-full flex-col">
      <Helmet>
        <title>{genPageTitle("房间列表")}</title>
      </Helmet>
      {isPC ? (
        <FixedHeaderWrapper
          pageName="房间列表"
          type="compact"
          goBack={() => {
            customNavigate(PATHS.HOME);
          }}
        >
          <div className="flex w-full items-center gap-6">
            <div className="w-1/2 cursor-pointer">
              <HeaderLodgingDates
                value={occupancy}
                onChange={changeOccupancy}
              />
            </div>
            <div className="w-1/2 cursor-pointer">
              <SearchArea
                address={address}
                onSearchArea={showSearchPopup}
                deleteSearchArea={deleteAddress}
              />
            </div>
          </div>
        </FixedHeaderWrapper>
      ) : (
        <>
          <NavBar
            title="房间列表"
            leftText="返回"
            placeholder={true}
            fixed={true}
            zIndex={50}
            onClickLeft={() => {
              customNavigate(PATHS.HOME);
            }}
          />
          <div className="bg-page-bg sticky inset-x-0 top-10 z-10 flex space-x-2 px-4 py-3">
            <div
              className="flex flex-col justify-center rounded-xl bg-white px-3"
              onClick={showDateRangePopup}
            >
              <div className="flex items-center text-xs font-medium">
                <span className="text-text-666 mr-2">住</span>
                <span className="text-easbnb-brand">
                  {occupancy?.startDate
                    ? toYYYYMMDD(occupancy?.startDate)
                    : "请选择"}
                </span>
              </div>
              <div className="flex items-center text-xs font-medium">
                <span className="text-text-666 mr-2">离</span>
                <span className="text-easbnb-brand">
                  {occupancy?.endDate
                    ? toYYYYMMDD(occupancy?.endDate)
                    : "请选择"}
                </span>
              </div>
            </div>
            <SearchArea
              address={address}
              onSearchArea={showSearchPopup}
              deleteSearchArea={deleteAddress}
            />
          </div>
        </>
      )}

      {loading ? (
        <Loading />
      ) : (
        <div
          ref={ref}
          className={clsx(
            isPC ? " mt-5" : "size-full  space-y-6",
            "bg-page-bg flex flex-1 flex-col overflow-y-auto px-4 ",
          )}
        >
          {listData?.list.map((roomInList) => (
            <RoomListItem
              roomInList={roomInList}
              key={roomInList.room.id}
              isLarge={isLarge}
            />
          ))}

          {listData?.list.length === 0 ? (
            <div className="flex flex-col items-center pt-24">
              <EmptySearch />
              <div className="text-text-4 mt-3 text-sm">暂无搜索结果</div>
            </div>
          ) : (
            <div
              className={clsx(
                isPC ? "px-3 py-6" : "py-6",
                "text-text-3 flex justify-center  text-sm",
              )}
            >
              {noMore ? "已经到底啦～" : "加载中..."}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const RoomListItem: React.FC<{
  roomInList: room_type.RoomInList;
  isLarge?: boolean;
}> = ({ roomInList, isLarge }) => {
  const customNavigate = useCustomNavigate();
  const isPC = useIsPC();

  const roomInfoText = getRoomInfo({
    room: roomInList.room,
  });
  const distanceText = getDistanceInfo(roomInList?.distance ?? "");

  const goToRoomDetail = (id: string) => {
    customNavigate(PATHS.ROOM, {
      id,
      state: {
        backPath: PATHS.ROOM_SEARCH,
      },
    });
  };

  return (
    <div
      className={clsx(
        {
          "mx-auto w-[80vw] cursor-pointer min-w-[720px] max-w-screen-lg rounded-xl hover:shadow-[0px_4px_24px_0px_#272C3E0F]":
            isPC,
        },
        "mb-3 flex cursor-pointer flex-col",
      )}
      key={roomInList.room.id}
      onClick={() => goToRoomDetail(roomInList.room.id)}
    >
      {isPC ? (
        <Card className="flex gap-6 p-2 ">
          <div
            className={clsx(isLarge ? "w-[432px]" : "w-[340px]", "h-[260px]")}
          >
            <RoomListItemImage roomInList={roomInList} />
          </div>
          <div className="flex-1">
            <RoomListItemInfo
              roomInList={roomInList}
              distanceText={distanceText}
              roomInfoText={roomInfoText}
            />
          </div>
        </Card>
      ) : (
        <>
          <RoomListItemImage roomInList={roomInList} />
          <RoomListItemInfo
            roomInList={roomInList}
            distanceText={distanceText}
            roomInfoText={roomInfoText}
          />
        </>
      )}
    </div>
  );
};

const RoomListItemInfo: React.FC<{
  roomInList: room_type.RoomInList;
  distanceText?: string;
  roomInfoText?: string;
}> = ({ roomInList, distanceText, roomInfoText }) => {
  const isPC = useIsPC();

  return (
    <div className={clsx({ "py-4": isPC }, "flex flex-col")}>
      <div
        className={clsx(
          {
            "border-b border-[#E9F0FF] pb-4": isPC,
          },
          "flex flex-col",
        )}
      >
        <div
          className={clsx(
            isPC ? "line-clamp-2 whitespace-pre-line" : "",
            "text-text-1 text-xl font-medium",
          )}
        >
          {roomInList.room.title}
        </div>
        <RentDiscount discounts={roomInList.room.discounts} />

        {distanceText ||
        !isEmpty(distanceText) ||
        roomInList.room.roomSize ||
        roomInList.room.personCapacity ? (
          <div className={clsx("mt-2.5 flex items-center space-x-2")}>
            {distanceText && !isEmpty(distanceText) && (
              <span className="flex items-center rounded-md border border-[#EC642B] bg-[#FDF6EF] px-1.5 py-1 text-sm ">
                <Distance className="size-3" />
                <span className="ml-1 text-[#EC642B]">{distanceText}</span>
              </span>
            )}
            {roomInList.room.roomSize ? (
              <span className="text-text-666 text-sm">{roomInfoText}</span>
            ) : null}
            {roomInList.room.personCapacity ? (
              <span className="text-easbnb-brand text-sm">
                可住{roomInList.room.personCapacity}人
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
      {roomInList.room.address && (
        <div
          className={clsx(isPC ? "mb-2.5 pt-4" : "mt-2", "flex items-start")}
        >
          <div>
            <LocationIcon className="mr-1 mt-0.5 size-4" />
          </div>
          <div className="text-text-666 text-sm">{roomInList.room.address}</div>
        </div>
      )}
      {roomInList.room.traffic && (
        <div className="my-2 flex items-start">
          <div>
            <SubwayIcon className="mr-1 mt-0.5 size-4" />
          </div>
          <div className="text-text-666 line-clamp-3 flex-1 whitespace-pre-line text-sm">
            {roomInList.room.traffic}
          </div>
        </div>
      )}

      {!roomInList.room.address && !roomInList.room.traffic ? (
        <div
          className={clsx(isPC ? "mb-2.5 pt-4" : "my-2", "flex items-start")}
        >
          <div>
            <LocationIcon className="mr-1 mt-0.5 size-4" />
          </div>
          <div className="text-text-666 text-sm">暂无位置、交通方式等信息</div>
        </div>
      ) : null}
    </div>
  );
};

const RoomListItemImage: React.FC<{
  roomInList: room_type.RoomInList;
}> = ({ roomInList }) => {
  const isPC = useIsPC();

  return (
    <div
      className={clsx(
        isPC ? "h-[260px]" : "h-[200px]",
        "relative mb-2 overflow-hidden rounded-xl bg-[#F2F0F4]",
      )}
    >
      {roomInList?.imageUri ? (
        <Image src={genSmallRoomCoverUrl(roomInList.imageUri)} fit="cover" />
      ) : (
        <div
          className={clsx(
            isPC ? "h-[260px]" : "h-[200px]",
            "flex w-full items-center justify-center bg-[#F2F0F4]",
          )}
        >
          <NoPhoto />
          <span className="text-text-3 absolute bottom-1/4 translate-y-1/2 text-sm">
            暂无图片
          </span>
        </div>
      )}
      {roomInList.payAmount ? (
        <div
          className={clsx(
            isPC ? "bottom-3 right-3 px-3" : "bottom-2 right-2 px-2",
            "absolute flex items-center space-x-2 rounded-xl bg-white/85 px-2 py-1",
          )}
        >
          <span className="text-lg font-medium text-[#F74D36]">
            {getMoneyText(roomInList.payAmount, roomInList.currency, {
              hidePlusSign: true,
            })}
          </span>
          {roomInList.fullAmount &&
            roomInList.payAmount < roomInList.fullAmount && (
              <span className="text-text-2 text-xs font-medium line-through">
                {getMoneyText(roomInList.fullAmount, roomInList.currency, {
                  hideLabel: true,
                  hidePlusSign: true,
                })}
              </span>
            )}
        </div>
      ) : null}
      {roomInList.lowestPrice ? (
        <div
          className={clsx(
            isPC ? "bottom-3 right-3 px-3" : "bottom-2 right-2 px-2",
            "absolute flex items-center space-x-2 rounded-xl bg-white/85 py-1",
          )}
        >
          <span className="text-lg font-medium text-[#F74D36]">
            {getMoneyText(roomInList.lowestPrice, roomInList.currency, {
              hidePlusSign: true,
            })}
          </span>
          <span className="text-text-2 text-xs font-medium"> 起/晚 </span>
        </div>
      ) : null}
    </div>
  );
};

const SearchArea: React.FC<{
  address: { name: string; point: Point } | undefined;
  onSearchArea: () => void;
  deleteSearchArea: (e: any) => void;
}> = ({ address, onSearchArea, deleteSearchArea }) => {
  const isPC = useIsPC();

  return (
    <div
      className={clsx(
        isPC ? "bg-[#F5F7FB]" : "bg-white",
        "group flex flex-1 cursor-pointer items-center space-x-2 rounded-xl p-3",
      )}
      onClick={onSearchArea}
    >
      <SearchIcon className="size-4" />
      {address?.name ? (
        <div className="text-text-1 line-clamp-1 w-full flex-1 text-ellipsis text-sm">
          {address?.name}
        </div>
      ) : (
        <div className="text-text-4 w-full flex-1 text-sm ">
          搜索附近的地区或车站
        </div>
      )}

      <CancelIcon
        className={clsx(
          { "group-hover:block": address },
          "hidden size-4 cursor-pointer",
        )}
        onClick={(e) => deleteSearchArea(e)}
      />
    </div>
  );
};
