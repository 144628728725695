import dayjs from "dayjs";
import { type FormattedRes } from "ahooks/lib/useCountDown";
import clsx from "clsx";
import type * as order_type from "$/api_out/http_resv/order/order_type";
import { Card } from "@/components";
import {
  getCurrentTimeZoneDate,
  OrderStatusEnum,
  getOccupancyTime,
} from "@/utils/order";
import { getMoneyText } from "@/utils/money";
import { useIsPC } from "@/stores";

interface OrderStatusCardProps {
  orderInDetail: order_type.OrderInDetail;
  orderStatus?: OrderStatusEnum;
  formattedRes: FormattedRes;
}
export const OrderStatusCard: React.FC<OrderStatusCardProps> = ({
  orderInDetail,
  orderStatus,
  formattedRes,
}) => {
  const isPC = useIsPC();

  if (orderStatus === OrderStatusEnum.Unpaid) {
    return (
      <Card
        className={clsx({
          "px-6": isPC,
        })}
      >
        <div className="mb-3 flex items-center space-x-6">
          <span className="text-text-1 text-base font-medium">待付款</span>
          <span className="text-danger  text-2xl font-medium">
            {getMoneyText(
              orderInDetail.cost.payAmount,
              orderInDetail.cost.currency,
              {
                hidePlusSign: true,
              },
            )}
          </span>
        </div>
        <div className="text-text-666 text-sm">
          支付剩余：{formattedRes.minutes.toString().padStart(2, "0")}分
          {formattedRes.seconds.toString().padStart(2, "0")}
          秒，超时将自动取消订单
        </div>
      </Card>
    );
  }

  if (orderStatus === OrderStatusEnum.Cancelled) {
    // 已付款 + 已取消
    if (orderInDetail.order.paidAt) {
      return (
        <Card
          className={clsx({
            "px-6": isPC,
          })}
          title="已取消"
        >
          <div className="text-text-666 pt-3 text-sm">
            订单已被取消，请联系客服
          </div>
        </Card>
      );
    }
    return (
      <Card
        className={clsx({
          "px-6": isPC,
        })}
        title="交易关闭"
      >
        <div className="text-text-666 pt-3 text-sm ">
          付款时间超时，订单自动关闭
        </div>
      </Card>
    );
  }

  if (orderStatus === OrderStatusEnum.WaitingCheckIn) {
    const checkInTime = getOccupancyTime(orderInDetail.order.checkInTime);

    return (
      <Card
        className={clsx({
          "px-6": isPC,
        })}
      >
        <div className="mb-3 flex items-center space-x-1">
          <span className="text-easbnb-brand text-2xl font-medium">
            {Math.max(
              dayjs(orderInDetail.order.startDate).diff(
                getCurrentTimeZoneDate().startOf("day"),
                "day",
              ),
              1,
            )}
            天
          </span>
          <span className="text-text-1 text-base font-medium">后入住</span>
        </div>
        <div className="text-text-666 text-sm">
          请您准备好相关证件于当天{checkInTime}前办理入住
        </div>
      </Card>
    );
  }

  if (orderStatus === OrderStatusEnum.CheckInToday) {
    const checkInTime = getOccupancyTime(orderInDetail.order.checkInTime);

    return (
      <Card
        className={clsx({
          "px-6": isPC,
        })}
        titleClassName="text-easbnb-brand"
        title="今日入住"
        titleColor="#6f9f0a"
      >
        <div className="text-text-666 pt-3 text-sm">
          请您准备好相关证件于今天{checkInTime}前办理入住
        </div>
      </Card>
    );
  }

  if (orderStatus === OrderStatusEnum.CheckOutToday) {
    const checkOutTime = getOccupancyTime(orderInDetail.order.checkOutTime);

    return (
      <Card
        title="今日退房"
        titleColor="#FFC718"
        className={clsx({
          "px-6": isPC,
        })}
      >
        <div className="text-text-666 pt-3 text-sm">
          请在今天{checkOutTime}之前完成退房
        </div>
      </Card>
    );
  }

  if (orderStatus === OrderStatusEnum.CheckIn) {
    return (
      <Card
        title="入住中"
        titleColor="#F74D36"
        className={clsx({
          "px-6": isPC,
        })}
      >
        <div className="text-text-666 pt-3 text-sm">
          欢迎入住！祝您有一个愉快的住宿体验
        </div>
      </Card>
    );
  }

  if (orderStatus === OrderStatusEnum.CheckedOut) {
    return (
      <Card
        title="已退房"
        titleColor="#979797"
        className={clsx({
          "px-6": isPC,
        })}
      >
        <div className="text-text-666 pt-3 text-sm">
          此笔订单已完成，期待您下次入住
        </div>
      </Card>
    );
  }

  return null;
};
