import dayjs from "dayjs";
import { isUndefined } from "lodash-es";
import clsx from "clsx";
import { type OrderInDetail } from "$/api_out/http_resv/order/order_type";
import { Card } from "@/components";
import { PLACEHOLDER_TEXT } from "@/constants/text";
import { copyToClipboard, parseToNumber, toYYYYMMDDHHmmss } from "@/lib/utils";
import { useIsPC } from "@/stores";

interface OrderInfoCardProps {
  data: OrderInDetail;
}

export const OrderInfoCard: React.FC<OrderInfoCardProps> = ({ data }) => {
  const isPC = useIsPC();

  if (!data?.tenant) {
    return null;
  }

  return (
    <Card
      className={clsx({
        "p-6": isPC,
      })}
      title="订单信息"
    >
      <div className="flex flex-col space-y-4 pt-3">
        <div className="flex items-center">
          <div className="text-text-666 text-sm">订单编号</div>
          <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis text-right text-sm">
            {data.order.id}
          </div>
          <div
            className="text-easbnb-brand ml-2 cursor-pointer text-sm"
            onClick={() => {
              copyToClipboard(data.order.id);
            }}
          >
            复制
          </div>
        </div>
        <div className="flex items-center">
          <div className="text-text-666 pr-6 text-sm">创建时间</div>
          <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis text-right text-sm">
            {parseToNumber(data.order.createdAt)
              ? toYYYYMMDDHHmmss(dayjs.unix(Number(data.order.createdAt)))
              : PLACEHOLDER_TEXT}
          </div>
        </div>

        {/* 只有支付成功才展示 */}
        {!isUndefined(data.order.paidAt) && (
          <div className="flex items-center">
            <div className="text-text-666 pr-6 text-sm">支付时间</div>
            <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis text-right text-sm">
              {parseToNumber(data.order.paidAt)
                ? toYYYYMMDDHHmmss(dayjs.unix(Number(data.order.paidAt)))
                : PLACEHOLDER_TEXT}
            </div>
          </div>
        )}
      </div>
    </Card>
  );
};
