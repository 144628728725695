import { useMemo, useState } from "react";
import { Form, NavBar, Toast } from "react-vant";
import { Helmet } from "react-helmet";
import { useRequest } from "ahooks";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import NiceModal from "@ebay/nice-modal-react";
import { RoomService } from "$/api_out/http_resv/room/room_srv";
import { OrderService } from "$/api_out/http_resv/order/order_srv";
import type * as order_srv from "$/api_out/http_resv/order/order_srv";
import type * as common_type from "$/api_out/http_resv/common/common_type";
import {
  Loading,
  FixedBottomWrapper,
  ErrorComp,
  FixedHeaderWrapper,
  PriceCard,
  PriceDetailPopup,
  RentTag,
} from "@/components";
import { RoomCard, CheckInOutCard } from "../components";
import { genPageTitle } from "@/utils";
import { useCustomNavigate, useResvInfo } from "@/hooks";
import { PATHS } from "@/constants/path";
import { getMoneyText } from "@/utils/money";
import { useUserInfoStore } from "@/stores/useUserInfoStore";
import { makeStorageUrl } from "@/utils/file";
import { useIsPC } from "@/stores";
import { PersonInfoCard } from "./PersonInfoCard";
import { DetailRoomCard } from "../Detail/components";
import ArrowUp from "@/assets/icons/arrow_up.svg?react";
import ArrowDown from "@/assets/icons/arrow_down.svg?react";
import { CheckOutPolicyCard } from "@/pages/Room/Detail/components";
import { type Currency } from "$/api_out/http_pms/const/const_enum";
import { type CostDetail } from "$/api_out/http_resv/room/room_type";

export const OrderConfirm = () => {
  const customNavigate = useCustomNavigate();

  const [form] = Form.useForm();
  const params = useParams<{ id?: string }>();
  const id = params.id;
  const fetchUserInfo = useUserInfoStore((s) => s.fetchUserInfo);
  const { occupancy, adultCount } = useResvInfo();
  const isPC = useIsPC();
  const [priceDetailRender, setPriceDetailRender] = useState(false);

  const nights = useMemo(() => {
    if (!occupancy) {
      return 1;
    }

    return dayjs(occupancy.endDate).diff(occupancy.startDate, "day");
  }, [occupancy]);

  const {
    data,
    loading,
    error: roomError,
  } = useRequest(
    async () => {
      const resp = await RoomService.Get({
        id: id!,
        priceQuery: {
          ...occupancy,
          adultCount,
        },
      });

      return resp.room;
    },
    { ready: Boolean(id) },
  );

  const { run, error: bookError } = useRequest(
    async (req: order_srv.BookReq) => {
      Toast.loading({
        message: "订单创建中",
        duration: 0,
        forbidClick: true,
        overlay: true,
      });
      const resp = await OrderService.Book(req);
      if (resp.isNewAccount) {
        await fetchUserInfo();
      }
      Toast.clear();

      // 当前订单不需要签署协议时，直接跳转到支付
      const path = resp.order.needSignContract
        ? PATHS.ORDER_AGREEMENT
        : PATHS.PAY;
      customNavigate(path, {
        id: resp.order.id,
        state: {
          isSigned: false,
        },
      });
      return resp;
    },
    {
      manual: true,
      onError(e) {
        Toast.clear();
        console.error(e);
        Toast({
          type: "fail",
          message: e.message,
        });
      },
    },
  );

  const goToPay = async () => {
    if (!data?.canBook || !data.cost) {
      return;
    }
    // 校验用户信息是否填写完善
    const values = await form.validateFields();
    const tenant: common_type.Tenant = {
      name: values?.name || "",
      email: values?.email || "",
      countryCode: values?.mobile?.countryCode || "",
      phoneNumber: values?.mobile?.phoneNumber || "",
    };

    const remark = values?.remark || "";

    if (!tenant.name || !tenant.email) {
      Toast({
        type: "fail",
        message: "联系人信息填写有误",
      });
      return;
    }

    if (!id || !occupancy || !data) {
      Toast({
        type: "fail",
        message: "获取房间价格信息异常",
      });
      return;
    }

    void hidePriceDetailPopup();

    run({
      roomId: id,
      tenant,
      remark,
      priceQuery: {
        ...occupancy,
        adultCount,
      },
      checkPrice: {
        currency: data.cost.currency,
        amount: data.cost.payAmount,
      },
    });
  };

  if (!id) {
    return (
      <div className="flex h-[50vh] w-full items-center justify-center">
        <Helmet>
          <title>{genPageTitle("确认订单")}</title>
        </Helmet>
        <div className="text-text-4 text-sm">无法获取房间信息</div>
      </div>
    );
  }

  if (roomError || bookError) {
    return <ErrorComp error={roomError || bookError} />;
  }

  const goBack = () => {
    customNavigate(PATHS.ROOM, {
      id,
    });
  };

  const showPriceDetailPopup = async (cost: CostDetail) => {
    setPriceDetailRender(true);

    await NiceModal.show(PriceDetailPopup, {
      cost,
      callBack: setPriceDetailRender,
    });
  };

  const hidePriceDetailPopup = async () => {
    setPriceDetailRender(false);

    await NiceModal.hide(PriceDetailPopup);
  };

  return (
    <div className="flex size-full flex-col">
      <Helmet>
        <title>{genPageTitle("确认订单")}</title>
      </Helmet>
      {isPC ? (
        <FixedHeaderWrapper pageName="确认订单" goBack={goBack} />
      ) : (
        <NavBar
          title="确认订单"
          leftText="返回"
          placeholder={true}
          zIndex={50}
          onClickLeft={goBack}
        />
      )}

      {!loading && data?.cost ? (
        <div
          className={clsx(
            isPC
              ? "mx-auto w-[80vw] min-w-[720px] max-w-screen-lg space-y-4"
              : "space-y-3 overflow-y-auto px-4 pb-24",
            "h-full pt-3 ",
          )}
        >
          {isPC ? (
            <>
              <DetailRoomCard
                room={data.room}
                price={{
                  currency: data.cost?.currency as Currency,
                  amount: data.cost.payAmount,
                }}
                imageUri={makeStorageUrl(data.images[0]?.uri || "")}
              />

              <div className="flex space-x-4">
                <div className="flex-1">
                  <PersonInfoCard form={form} canBook={data.canBook} />
                </div>
                <div className="w-[360px]">
                  <CheckInOutCard
                    startDate={occupancy?.startDate}
                    endDate={occupancy?.endDate}
                    adultCount={adultCount}
                  >
                    <div>
                      {data.canBook && <PriceCard priceData={data} />}
                      <button
                        type="button"
                        className={clsx(
                          data.canBook && data.cost
                            ? "hover:bg-text-333 bg-[#3D3D3D]"
                            : "bg-[#979BAD]",
                          "mt-8 w-full rounded-full px-16 py-3 text-base font-medium text-white",
                        )}
                        onClick={goToPay}
                      >
                        预定
                      </button>
                    </div>
                  </CheckInOutCard>
                </div>
              </div>
            </>
          ) : (
            <>
              <RoomCard
                data={data}
                imageUri={makeStorageUrl(data.images[0]?.uri || "")}
              />
              <CheckInOutCard
                startDate={occupancy?.startDate}
                endDate={occupancy?.endDate}
                adultCount={adultCount}
                cost={data.cost}
                onClick={async () => {
                  priceDetailRender
                    ? await hidePriceDetailPopup()
                    : await showPriceDetailPopup(data.cost!);
                }}
              />
              <PersonInfoCard form={form} />
              <CheckOutPolicyCard data={data} />
            </>
          )}

          {occupancy && !isPC && (
            <FixedBottomWrapper className="flex h-20 w-full items-center justify-between border-t border-[#E9F0FF] bg-white px-6 py-3">
              <div className="flex flex-col">
                {!data.canBook && (
                  <span className="mr-2 text-xs text-[#EC642B]">
                    已选日期不可定,请重新选择
                  </span>
                )}

                {data.cost && data.canBook ? (
                  <>
                    <div className="inline-flex items-center">
                      <span className="text-danger text-xl font-medium">
                        {getMoneyText(data.cost.payAmount, data.cost.currency, {
                          hidePlusSign: true,
                        })}
                      </span>
                      <RentTag
                        discounts={data.cost.discounts}
                        className="ml-1.5"
                      />
                    </div>
                    <div className="text-text-2 flex items-center justify-start space-x-2 text-xs">
                      <span>
                        均价
                        {getMoneyText(
                          data.cost.payAmount / nights,
                          data.cost.currency,
                          { hideLabel: true, hidePlusSign: true },
                        )}
                        /晚
                      </span>
                      <div
                        onClick={async () => {
                          priceDetailRender
                            ? await hidePriceDetailPopup()
                            : await showPriceDetailPopup(data.cost!);
                        }}
                        className="text-easbnb-brand ml-2 flex items-center"
                      >
                        <span className="mr-2">明细</span>
                        {priceDetailRender ? <ArrowDown /> : <ArrowUp />}
                      </div>
                    </div>
                  </>
                ) : null}

                {data.lowestPrice ? (
                  <div className="flex flex-row items-center">
                    <span className="text-danger mr-1 text-xl font-medium">
                      {getMoneyText(
                        data.lowestPrice.amount,
                        data.lowestPrice.currency,
                        { hidePlusSign: true },
                      )}
                    </span>
                    <span className="text-text-2 flex flex-col items-center space-x-2 text-xs">
                      起/晚
                    </span>
                    <div
                      onClick={async () => {
                        setPriceDetailRender(!priceDetailRender);

                        priceDetailRender
                          ? await hidePriceDetailPopup()
                          : await showPriceDetailPopup(data.cost!);
                      }}
                      className="text-easbnb-brand ml-2 flex items-center"
                    >
                      <span className="mr-2">明细</span>
                      {priceDetailRender ? <ArrowDown /> : <ArrowUp />}
                    </div>
                  </div>
                ) : null}
              </div>
              <button
                type="button"
                className={clsx(
                  data.canBook && data.cost && occupancy
                    ? "hover:bg-text-333 bg-[#3D3D3D]"
                    : "bg-[#979BAD]",
                  "rounded-full px-12 py-3 text-base font-medium text-white",
                )}
                onClick={goToPay}
              >
                预定
              </button>
            </FixedBottomWrapper>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};
