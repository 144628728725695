import { useSetState } from "ahooks";
import { Form, type FormInstance, Input, Picker } from "react-vant";
import { useMemo } from "react";
import clsx from "clsx";
import CaretDownIcon from "@/assets/icons/caret_down.svg?react";
import { AREA_NUMBER } from "@/constants/areaNumber";

import { Card } from "@/components";
import { useIsPC } from "@/stores";
import TrumpetIcon from "@/assets/icons/trumpet.svg?react";
import { useUserInfoStore } from "@/stores/useUserInfoStore";
import { isEmail } from "@/utils/regex";

interface PersonInfoCardProps {
  form: FormInstance;
  canBook?: boolean;
}

interface MobileInputValue {
  countryCode: string;
  phoneNumber: string;
}

interface MobileInputProps {
  value?: MobileInputValue;
  onChange?: (value: MobileInputValue) => void;
}

// 自定义表单项
const MobileInput: React.FC<MobileInputProps> = ({
  value = { countryCode: "", phoneNumber: "" },
  onChange,
}) => {
  const trigger = (changedValue: Partial<MobileInputValue>) => {
    onChange?.({ ...value, ...changedValue });
  };

  const onPhoneNumberChange = (v: string) => {
    trigger({ phoneNumber: v });
  };

  const onCountryCodeChange = (v: string) => {
    trigger({ countryCode: v });
  };

  const areaNumber = useMemo(
    () =>
      AREA_NUMBER.map((item) => ({
        text: `${item.name_cn}(+${item.code})`,
        value: item.code,
      })),
    [],
  );

  return (
    <>
      <Picker
        popup={true}
        value={value.countryCode}
        placeholder={false}
        columns={areaNumber}
        onConfirm={onCountryCodeChange}
      >
        {(_, selectRow: any, actions) => {
          return (
            <div className="flex items-center">
              <div
                className="flex cursor-pointer items-center"
                onClick={() => actions.open()}
              >
                <div className="text-text-1 text-sm">+{selectRow?.value}</div>
                <CaretDownIcon className="size-4" />
              </div>
              <Input
                value={value.phoneNumber}
                placeholder="请填写手机号"
                autoCapitalize="off"
                autoCorrect="off"
                type="tel"
                className="flex-1"
                onChange={onPhoneNumberChange}
              />
            </div>
          );
        }}
      </Picker>
    </>
  );
};

export const ContactInfoForm: React.FC<{ form: FormInstance }> = ({ form }) => {
  const user = useUserInfoStore((s) => s.defaultTenant);
  const [initValues] = useSetState<{
    name: string;
    email: string;
    countryCode: string;
    phoneNumber: string;
  }>({
    name: user?.name || "",
    email: user?.email || "",
    countryCode: user?.countryCode || "86",
    phoneNumber: user?.phoneNumber || "",
  });

  return (
    <Form
      form={form}
      css={`
        flex: 1;
        margin-top: 12px;
        .rv-form-item {
          margin-left: -12px;
          padding-top: 18px;
        }
      `}
    >
      <Form.Item
        rules={[{ required: true, message: "请填写联系人姓名" }]}
        name="name"
        initialValue={initValues.name}
        label="姓名"
      >
        <Input placeholder="请填写联系人姓名" />
      </Form.Item>
      <Form.Item
        initialValue={initValues.email}
        rules={[
          { required: true, message: "请填写邮箱地址" },
          {
            message: "邮箱格式不正确",
            validator: (_, value, cb) => {
              if (!isEmail(value as string)) {
                return Promise.reject(new Error("邮箱格式不正确"));
              }
              return Promise.resolve("");
            },
            validateTrigger: "onBlur",
          },
        ]}
        name="email"
        label="邮箱地址"
      >
        <Input
          placeholder="请填写邮箱地址"
          autoCapitalize="off"
          autoCorrect="off"
          type="text"
        />
      </Form.Item>
      <Form.Item
        initialValue={{
          countryCode: initValues.countryCode,
          phoneNumber: initValues.phoneNumber,
        }}
        name="mobile"
        label="手机号"
      >
        <MobileInput />
      </Form.Item>
      <Form.Item name="remark" label="备注信息">
        <Input.TextArea placeholder="请填写备注信息" />
      </Form.Item>
    </Form>
  );
};

export const PersonInfoCard: React.FC<PersonInfoCardProps> = ({
  form,
  canBook,
}) => {
  const isPC = useIsPC();
  const user = useUserInfoStore((s) => s.userInfo);

  return (
    <Card
      title="联系人信息"
      className={clsx({
        "h-[360px] p-6": isPC,
      })}
    >
      {!user && (
        <div className="my-2 flex items-center rounded-full bg-[#FDF6EF] py-1.5 pl-2 text-xs text-[#EC642B]">
          <TrumpetIcon className="mr-1 size-4" />
          <div
            className={clsx(
              isPC ? "overflow-hidden text-ellipsis" : "overflow-x-auto",
              "no-scrollbar whitespace-nowrap",
            )}
          >
            您当前未登录，下单后会将账号密码发送至您填写的联系邮箱。
          </div>
        </div>
      )}
      {isPC && !canBook && (
        <div className="my-2 flex items-center rounded-full bg-[#FDF6EF] py-1.5 pl-2 text-xs text-[#EC642B]">
          <TrumpetIcon className="mr-1 size-4" />
          <div
            className={clsx(
              isPC ? "overflow-hidden text-ellipsis" : "overflow-x-auto",
              "no-scrollbar whitespace-nowrap",
            )}
          >
            已选日期不可定,请返回房间详情页重新选择。
          </div>
        </div>
      )}
      <ContactInfoForm form={form} />
    </Card>
  );
};
