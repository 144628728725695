import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Popup, Toast } from "react-vant";
import { useRequest } from "ahooks";
import { useState } from "react";
import clsx from "clsx";
import { SignService } from "$/api_out/http_resv/sign/sign_srv";
import HidePasswordIcon from "@/assets/icons/hide_password.svg?react";
import ShowPasswordIcon from "@/assets/icons/show_password.svg?react";
import { useUserInfoStore } from "@/stores/useUserInfoStore";
import { useIsPC } from "@/stores";
import { PrivacyPopup } from "../PrivacyPopup";

export const LoginPopup = NiceModal.create(() => {
  const modal = useModal();
  const isPC = useIsPC();

  const [state, setState] = useState({
    account: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [accountError, setAccountError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const setUserInfo = useUserInfoStore((s) => s.setUserInfo);

  const { runAsync } = useRequest(
    async () => {
      await SignService.SignInByAccount(state);
      const resp = await SignService.Login({});
      Toast.clear();
      Toast.success({ message: "登录成功", duration: 1000 });
      setUserInfo(resp.user);
      modal.resolve({});
      void modal.hide();
      return resp;
    },
    {
      manual: true,
      onError: (e) => {
        console.error(e);
      },
    },
  );

  const onLogin = async () => {
    if (!state.account) {
      setAccountError("请输入账号");
      return;
    }

    if (!state.password) {
      setPasswordError("请输入密码");
      return;
    }

    Toast.loading({
      message: "加载中...",
      duration: 0,
      forbidClick: true,
      overlay: true,
    });
    await runAsync();
  };

  return (
    <Popup
      visible={modal.visible}
      closeable={true}
      className={clsx(isPC ? "h-[460px] w-[452px]" : "h-4/5")}
      position={isPC ? "center" : "bottom"}
      round={true}
      destroyOnClose={true}
      onClose={async () => {
        await modal.hide();
      }}
      onClosed={() => {
        modal.remove();
      }}
    >
      <div className="bg-white p-8">
        {!isPC && <div className="mb-4 text-3xl font-medium">Hello~</div>}
        <div className="mb-8 text-2xl font-medium">
          {isPC ? "Hello! 欢迎登录" : "欢迎登录..."}
        </div>

        <div className="flex flex-col">
          <div className="mb-6">
            <div className="bg-input-bg-base/5 rounded-2xl px-6 py-4">
              <input
                placeholder="请输入账号"
                inputMode="text"
                autoCapitalize="off"
                autoCorrect="off"
                value={state.account}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setState((prev) => ({
                    ...prev,
                    account: e.target.value,
                  }));
                  if (accountError && e.target.value) {
                    setAccountError("");
                  }
                }}
                className="text-text-1 placeholder:text-text-4 w-full bg-transparent text-base"
              />
            </div>
            {Boolean(accountError) && (
              <div className="text-danger mt-2 px-6 text-sm">
                {accountError}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="mb-6">
            <div className="bg-input-bg-base/5 flex justify-between rounded-2xl px-6 py-4">
              <input
                placeholder="请输入密码"
                inputMode="email"
                autoCapitalize="off"
                autoCorrect="off"
                type={showPassword ? "text" : "password"}
                value={state.password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setState((prev) => ({
                    ...prev,
                    password: e.target.value,
                  }));
                  if (passwordError && e.target.value) {
                    setPasswordError("");
                  }
                }}
                className="text-text-1 placeholder:text-text-4 w-full bg-transparent text-base"
              />
              {showPassword ? (
                <ShowPasswordIcon
                  className="cursor-pointer"
                  onClick={() => {
                    setShowPassword(false);
                  }}
                />
              ) : (
                <HidePasswordIcon
                  className="cursor-pointer"
                  onClick={() => {
                    setShowPassword(true);
                  }}
                />
              )}
            </div>
            {Boolean(passwordError) && (
              <div className="text-danger mt-2 px-6 text-sm">
                {passwordError}
              </div>
            )}
          </div>

          <div className="flex justify-center">
            <button
              type="button"
              className="hover:bg-text-333 rounded-full bg-[#3D3D3D] px-32 py-3.5 text-center text-base font-medium text-white"
              onClick={onLogin}
            >
              登录
            </button>
          </div>
        </div>
        <div
          className={clsx(
            isPC ? "mt-12" : "mt-20",
            "flex justify-center text-xs",
          )}
        >
          <div className="text-text-3">登录即代表您已阅读并同意</div>
          <div
            onClick={async () => {
              await NiceModal.show(PrivacyPopup, {});
            }}
            className="text-easbnb-brand cursor-pointer"
          >
            《隐私政策》
          </div>
        </div>
      </div>
    </Popup>
  );
});
