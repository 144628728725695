import clsx from "clsx";
import { type RoomInDetail } from "$/api_out/http_resv/room/room_type";
import { Card } from "@/components";
import { useIsPC, useOrgInfoStore } from "@/stores";
import InfoIcon from "@/assets/icons/info.svg?react";

interface CheckOutPolicyCardProps {
  data: RoomInDetail;
}

export const CheckOutPolicyCard: React.FC<CheckOutPolicyCardProps> = ({
  data,
}) => {
  const isPC = useIsPC();

  const orgInfo = useOrgInfoStore((s) => s.data);

  if (!data?.policy && !orgInfo?.org?.handlingFeeRate) {
    return null;
  }

  return (
    <Card
      className={clsx({
        "p-6": isPC,
      })}
      title="退订政策"
    >
      {data.policy ? (
        <div className="text-text-666 whitespace-pre-line break-all pt-3 text-sm">
          {data?.policy}
        </div>
      ) : null}

      {orgInfo?.org?.handlingFeeRate ? (
        <div className="mt-2 flex rounded-[20px] bg-[#F5F7FB] p-2">
          <InfoIcon className="mr-1" />
          <span className="text-text-666 text-xs">
            退款时，交易手续费 ({(orgInfo?.org?.handlingFeeRate || 0) / 100}%)
            不退还。
          </span>
        </div>
      ) : null}
    </Card>
  );
};
