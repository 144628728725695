import NiceModal, { useModal } from "@ebay/nice-modal-react";
import clsx from "clsx";
import { Popup } from "react-vant";
import { isArray } from "lodash-es";
import { useState, useMemo } from "react";
import dayjs, { type Dayjs } from "dayjs";
import { useRequest } from "ahooks";
import {
  type ListRoomDayDetailResp,
  RoomService,
} from "$/api_out/http_resv/room/room_srv";
import { Loading } from "../../Loading";
import { CalendarCssWrapper } from "./styled";

import { isHotelIndustryWeekend, toYYYYMMDD } from "@/lib/utils";
import { type DateRange } from "../../LodgingDates";
import { useIsPC } from "@/stores";
import { getMoneyText } from "@/utils/money";

export interface DateRangePopupProps {
  value?: DateRange;
  roomId?: string;
}

export const DateRangePopup = NiceModal.create(
  ({ value: initValue, roomId }: DateRangePopupProps) => {
    const modal = useModal();
    const isPC = useIsPC();

    const { data, loading } = useRequest(
      async () => {
        const res = await RoomService.ListRoomDayDetail({
          roomId: roomId!,
        });
        const _roomDayMap: Record<
          string,
          ListRoomDayDetailResp.RoomDayDetail & {
            toDayjs: Dayjs;
            farthestOpenDay: Dayjs;
          }
        > = {};
        const detailsMapDate = Object.fromEntries(
          res.details.map((d) => [d.date, d]),
        );

        // 逆序计算每一个日期的，下一个关闭日期。（最大可选择的日期）
        const _minDay = dayjs(res.openStartDate).startOf("d");
        const _maxDay = dayjs(res.openEndDate).endOf("d");
        let _farthestOpenDay: Dayjs | undefined;

        const dayCount = _maxDay.diff(_minDay, "d") + 1;
        for (let d = dayCount - 1; d >= 0; d--) {
          const curDay = _minDay.add(d, "d");
          const curDateStr = toYYYYMMDD(curDay);
          const curRoomDay = detailsMapDate[curDateStr] || {
            date: curDateStr,
            isClosed: false,
            baseAmount: -1,
            minNights: 1,
          };
          if (!curRoomDay.minNights) {
            curRoomDay.minNights = 1;
          }
          if (!_farthestOpenDay) {
            _farthestOpenDay = curDay;
          }
          if (!curRoomDay.isClosed) {
            const openNights = _farthestOpenDay.diff(curDay, "d");
            if (openNights < curRoomDay.minNights) {
              curRoomDay.isClosed = true;
            }
          } else if (curRoomDay.isClosed) {
            _farthestOpenDay = curDay;
          }
          _roomDayMap[curDateStr] = {
            ...curRoomDay,
            toDayjs: curDay,
            farthestOpenDay: _farthestOpenDay,
          };
        }
        return {
          minDay: _minDay,
          maxDay: _maxDay,
          roomDayMap: _roomDayMap,
          currency: res.currency,
        };
      },
      {
        ready: Boolean(roomId),
      },
    );
    const { roomDayMap, minDay, maxDay, currency } = data || {
      minDay: dayjs().startOf("d"),
      maxDay: dayjs().add(6, "month").endOf("d"),
    };
    const [calendarMinDate, calendarMaxDate] = useMemo(
      () => [minDay.startOf("month").toDate(), maxDay.endOf("month").toDate()],
      [minDay, maxDay],
    );

    const onSubmit = () => {
      if (Boolean(invalidReason) || value?.length !== 2) return;
      modal.resolve({
        startDate: toYYYYMMDD(value[0]),
        endDate: toYYYYMMDD(value[1]),
      });
      void modal.hide();
    };

    const [value, setValue] = useState(() => {
      return initValue?.startDate && initValue?.endDate
        ? [
            dayjs(initValue.startDate).toDate(),
            dayjs(initValue.endDate).toDate(),
          ]
        : undefined;
    });

    const nights = useMemo(
      () => (value ? dayjs(value[1]).diff(dayjs(value[0]), "day") : 0),
      [value],
    );

    // 实时状态：日历不能预定
    const invalidReason = useMemo(() => {
      // 缺少数据，不判断
      if (!roomId || !roomDayMap || !value?.[0] || !value?.[1]) return "";
      const startDay = dayjs(value[0]);
      const startRoomDay = roomDayMap?.[toYYYYMMDD(startDay)];
      const endDay = dayjs(value[1]);
      const endRoomDay = roomDayMap?.[toYYYYMMDD(endDay)];
      // 数据非法 / 不再范围内，直接true
      if (!startRoomDay || !endRoomDay) return "所选日期不在有效范围内";
      if (endDay.isAfter(startRoomDay.farthestOpenDay))
        return "所选日期已被占用";
      // 所选日期范围内，最大的起订间夜数，用来判断是否满足最小间夜数限制
      let minNightsBiggest = 0;
      const maxDays = endDay.diff(startDay, "d");
      for (let d = 0; d < maxDays; d++) {
        const curStr = toYYYYMMDD(startDay.add(d, "d"));
        const roomDay = roomDayMap?.[curStr];
        if (roomDay?.minNights && roomDay.minNights > minNightsBiggest) {
          minNightsBiggest = roomDay.minNights;
        }
      }
      if (endDay.diff(startDay, "d") < minNightsBiggest) {
        return `所选日期 ${minNightsBiggest} 晚起订`;
      }
      return "";
    }, [value, roomDayMap, roomId]);

    // 选择开始日期后，根据最小起订天数，提前计算所有不可用日期
    const minNightsLimitMap = useMemo(() => {
      const _minNightsLimitMap: Record<string, number> = {};
      if (!maxDay || value?.length !== 1) return _minNightsLimitMap;
      const startDay = dayjs(value[0]);
      const maxDays = maxDay.diff(startDay, "d");
      let effectNights = 0;
      let limitNights = 0;

      for (let d = 0; d <= maxDays; d++) {
        const curDateStr = toYYYYMMDD(startDay.add(d, "d"));
        if (effectNights > 0) {
          effectNights--;
          _minNightsLimitMap[curDateStr] = limitNights;
        }

        const roomDay = roomDayMap?.[curDateStr];
        if (roomDay?.isClosed) {
          break;
        }
        const minNights = roomDay?.minNights || 1;
        if (minNights - d - 1 > effectNights) {
          effectNights = minNights - d - 1;
          limitNights = minNights;
        }
      }
      return _minNightsLimitMap;
    }, [value, maxDay, roomDayMap]);

    return (
      <Popup
        visible={modal.visible}
        closeable={true}
        className={clsx(isPC ? "h-[520px] w-[500px]" : "h-4/5")}
        position={isPC ? "center" : "bottom"}
        round={true}
        destroyOnClose={true}
        onClose={() => {
          modal.reject();
          void modal.hide();
        }}
        onClosed={modal.remove}
      >
        {loading ? (
          <div className="flex h-1/2 w-full items-center justify-center">
            <Loading />
          </div>
        ) : (
          <CalendarCssWrapper
            $canNotBook={Boolean(invalidReason)}
            value={value}
            onSelect={(v) => {
              if (!isArray(v)) return;
              setValue(v[0] ? v : undefined);
            }}
            defaultValue={value}
            poppable={false}
            type="range"
            minDate={calendarMinDate}
            maxDate={calendarMaxDate}
            showConfirm={false}
            title={
              <div className="relative">
                <div
                  className="absolute h-full cursor-pointer px-5 py-4 text-sm font-normal text-[#799e31] hover:text-[#a2bc65]"
                  onClick={() => {
                    modal.resolve(undefined);
                    void modal.hide();
                  }}
                >
                  清空
                </div>
                <div>选择入离日期</div>
                <div />
              </div>
            }
            footer={
              <div className="flex h-20 w-full items-center justify-between px-2 py-3">
                <div>
                  {invalidReason ? (
                    <span className="mr-2 text-sm text-[#EC642B]">
                      {invalidReason}
                    </span>
                  ) : (
                    <span className="text-text-1 mr-2 text-sm">
                      {!value?.[0]
                        ? "请选择入住日期"
                        : !value?.[1]
                          ? "请选择退房日期"
                          : nights > 0
                            ? `已选: ${nights + 1}天${nights}晚`
                            : null}
                    </span>
                  )}
                </div>
                <div
                  onClick={onSubmit}
                  className={clsx(
                    "flex h-12 w-40 cursor-pointer items-center justify-center rounded-full border text-base font-medium text-white",
                    invalidReason || value?.length !== 2
                      ? "border-[#979BAD] bg-[#979BAD]"
                      : " border-text-1  hover:bg-text-333 bg-[#3D3D3D]",
                  )}
                >
                  确定
                </div>
              </div>
            }
            formatter={(day) => {
              // 对选择中的起止日期加标记
              const className: string[] = [];
              if (day.type === "start") {
                className.push("check-in");
              } else if (day.type === "end") {
                className.push("check-out");
              }

              const curDate = day.date;
              if (!curDate) return day;
              // 范围内手动控制 middle 逻辑（选择中），否则禁用日期不会自动加 middle 样式
              if (
                value?.length === 2 &&
                curDate >= value[0]! &&
                curDate <= value[1]!
              ) {
                className.push("rv-calendar__day--middle");
              }

              const curDay = dayjs(day.date);
              // 周末红色
              if (isHotelIndustryWeekend(curDay)) {
                className.push("weekend");
              }

              if (!roomId) {
                if (curDay.isBefore(minDay) || curDay.isAfter(maxDay)) {
                  day.bottomInfo = "不可用";
                  day.type = "disabled";
                }
                day.className = className.join(" ");
                return day;
              }

              const curYMD = toYYYYMMDD(curDay);
              const curRoomDay = roomDayMap?.[curYMD];
              // 可用区间范围外，直接不可用
              if (!curRoomDay) {
                day.bottomInfo = "不可用";
                day.type = "disabled";
                day.className = className.join(" ");
                return day;
              }

              // 对于只选择了开始的状态，之后的日期特殊处理逻辑
              if (value?.length === 1 && curDate > value[0]!) {
                const startDay = roomDayMap[toYYYYMMDD(value[0])]!;
                if (curDay.isAfter(startDay.farthestOpenDay)) {
                  day.type = "disabled";
                  day.bottomInfo = "关闭";
                } else if (curYMD in minNightsLimitMap) {
                  day.type = "disabled";
                  day.bottomInfo = `${minNightsLimitMap[curYMD]} 晚起订`;
                }
              }
              // pms占用，标记为关闭
              else if (curRoomDay.isClosed) {
                day.type = "disabled";
                day.bottomInfo = "关闭";
              }

              if (day.type !== "disabled") {
                day.bottomInfo = getMoneyText(
                  curRoomDay.baseAmount,
                  currency!,
                  {
                    hide00Dec: true,
                    hidePlusSign: true,
                    hideLabel: true,
                  },
                );
              }

              day.className = className.join(" ");
              return day;
            }}
          />
        )}
      </Popup>
    );
  },
);
