import { useMemo } from "react";
import { isEmpty } from "lodash-es";
import clsx from "clsx";
import type * as org_srv from "$/api_out/http_resv/org/org_srv";
import { PayKind } from "$/api_out/http_pms/const/const_enum";
import { type PayChannel } from "$/api_out/http_resv/org/org_type";
import { PAYCHANNEL_ICON } from "@/constants/icon";
import { isInWeChat } from "@/utils/environment";
import { Card } from "@/components";
import SelectedIcon from "@/assets/icons/selected.svg?react";
import UnselectIcon from "@/assets/icons/unselect.svg?react";
import NoPayChannelIcon from "@/assets/icons/no_paychannel.svg?react";
import { useIsPC } from "@/stores";

interface PayChannelsProps {
  payChannels: org_srv.Org["payChannels"];
  value?: PayChannel;
  onChange: (value?: PayChannel) => void;
  cantPay?: boolean;
  pay?: () => void;
}

export const PayChannels: React.FC<PayChannelsProps> = ({
  payChannels,
  value,
  onChange,
  cantPay,
  pay,
}: PayChannelsProps) => {
  const isEmptyPayChannels = isEmpty(payChannels);
  const isPC = useIsPC();

  const currentPayChannels = useMemo(() => {
    if (isEmptyPayChannels) return [];

    if (isInWeChat()) {
      return payChannels.filter((item) => item.payKind !== PayKind.AliPay);
    }

    return payChannels;
  }, []);

  if (isEmptyPayChannels) {
    return (
      <Card
        className={clsx(
          {
            "mx-auto w-[80vw] max-w-[1024px]": isPC,
          },
          "flex h-[500px] flex-col items-center justify-center ",
        )}
      >
        <NoPayChannelIcon className="size-[100px]" />
        <span className="text-text-2 text-sm font-normal">
          暂无可用支付方式，请联系客服
        </span>
      </Card>
    );
  }

  return isPC ? (
    <Card className="mx-auto mb-3 flex h-[500px] w-[80vw] min-w-[720px] max-w-screen-lg flex-col rounded-2xl p-6">
      <div className="text-text-333 border-b border-[#E9F0FF] pb-4 text-sm">
        选择以下支付方式付款
      </div>
      <div className="mt-16 flex justify-center gap-8">
        {currentPayChannels.map((payChannel) => {
          return (
            <div
              className={clsx(
                value?.id === payChannel.id
                  ? "border-easbnb-brand"
                  : "border-[#D2DDF8]",
                "flex size-[160px] cursor-pointer flex-col items-center justify-center rounded-lg border  py-4",
              )}
              key={payChannel.id}
              onClick={() => onChange(payChannel)}
            >
              <img
                className="size-[64px]"
                src={PAYCHANNEL_ICON[payChannel.payKind]?.icon}
                alt={payChannel.name}
              />
              <span className="text-text-666 mt-3 text-center text-sm">
                {PAYCHANNEL_ICON[payChannel.payKind].label}
              </span>
            </div>
          );
        })}
      </div>
      <button
        type="button"
        className={clsx(
          "mx-auto mt-12 w-[240px] rounded-full px-20 py-3 text-base font-medium text-white",
          cantPay ? "bg-text-2" : "hover:bg-text-333 bg-[#3D3D3D]",
        )}
        onClick={pay}
      >
        确认支付
      </button>
    </Card>
  ) : (
    <Card className="mb-3">
      {currentPayChannels.map((payChannel) => {
        return (
          <div
            className="flex justify-between py-4"
            key={payChannel.id}
            onClick={() => onChange(payChannel)}
          >
            <div className="flex items-center space-x-2">
              <img
                className="size-[22px]"
                src={PAYCHANNEL_ICON[payChannel.payKind]?.icon}
                alt={payChannel.name}
              />
              <span className="text-text-2 text-base">
                {PAYCHANNEL_ICON[payChannel.payKind].label}
              </span>
            </div>
            <div className="flex items-center space-x-2">
              {value?.id === payChannel.id ? (
                <SelectedIcon className="size-[22px]" />
              ) : (
                <UnselectIcon className="size-[22px]" />
              )}
            </div>
          </div>
        );
      })}
    </Card>
  );
};
