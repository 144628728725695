import { Helmet } from "react-helmet";
import NiceModal from "@ebay/nice-modal-react";
import { isArray, isNil } from "lodash-es";
import clsx from "clsx";
import * as const_enum from "$/api_out/http_pms/const/const_enum";
import type * as OrgConfig from "$/api_out/http_resv/org/org_type";
import defaultBgImg from "@/assets/images/default_bg.png";
import defaultWellcomeTextImg from "@/assets/images/default_wellcometext.png";
import CancelIcon from "@/assets/icons/cancel.svg?react";
import IconSearch from "@/assets/icons/search.svg?react";
import {
  SearchPopup,
  TenantCount,
  LodgingDates,
  FixedHeaderWrapper,
} from "@/components";
import { PATHS } from "@/constants/path";
import { genPageTitle } from "@/utils";
import { makeStorageUrl } from "@/utils/file";
import { useCustomNavigate, useResvInfo } from "@/hooks";
import { AREA_OPTION_MAP } from "@/constants/options";
import { useOrgInfoStore, useIsPC } from "@/stores";

export interface RoomSearchState {
  startDate?: string;
  endDate?: string;
  adultCount?: number;
  address?: string;
  lat?: number;
  lng?: number;
}

export const Home = () => {
  const customNavigate = useCustomNavigate();
  const {
    address,
    setAddress,
    occupancy,
    setOccupancy,
    adultCount,
    setAdultCount,
  } = useResvInfo();

  const isPC = useIsPC();

  const onChooseHotPlace = (item: OrgConfig.PopularLocation) => {
    setAddress(item);
    customNavigate(PATHS.ROOM_SEARCH);
  };

  const orgInfo = useOrgInfoStore((s) => s.data);

  const areaLabel = !isNil(orgInfo?.org?.area)
    ? AREA_OPTION_MAP[orgInfo?.org.area].label
    : AREA_OPTION_MAP[const_enum.Area.UnknownArea].label;

  const showSearchPopup = async () => {
    const res: OrgConfig.PopularLocation = await NiceModal.show(
      SearchPopup,
      {},
    );
    setAddress(res);
  };

  const onSearch = () => {
    customNavigate(PATHS.ROOM_SEARCH);
  };

  const deleteAddress = (e: React.MouseEvent) => {
    e.stopPropagation();

    setAddress(undefined);
  };

  return (
    <div
      className={clsx(
        {
          "h-full": isPC,
        },
        "flex flex-col bg-[#FAFAFA]",
      )}
    >
      <Helmet>
        <title>{genPageTitle("首页")}</title>
      </Helmet>

      {isPC && <FixedHeaderWrapper onlyTitle={true} />}
      <div
        className={clsx(
          isPC ? "h-full" : "h-[35vh]",
          "relative flex items-center justify-center bg-cover bg-center bg-no-repeat object-contain",
        )}
        style={{
          height: isPC ? "" : "35vh",
          backgroundImage: `${
            orgInfo?.org?.mainImage
              ? `url("${makeStorageUrl(orgInfo.org.mainImage)}")`
              : `url("${defaultBgImg}")`
          }`,
        }}
      >
        {orgInfo?.org?.welcomeKind === 0 && (
          <div
            className={clsx(
              isPC
                ? "top-[100px] max-h-[180px] max-w-[700px]"
                : "top-1/2 h-[18vh] max-h-[120px] w-4/5 -translate-y-1/2",
              "absolute size-full bg-contain bg-center bg-no-repeat object-cover",
            )}
            style={{
              backgroundImage: `url("${defaultWellcomeTextImg}")`,
            }}
          />
        )}

        {orgInfo?.org?.welcomeKind !== 0 && (
          <>
            {orgInfo?.org?.welcomeText && orgInfo?.org?.welcomeKind === 1 && (
              <span
                className={clsx(
                  isPC
                    ? "top-[100px] max-w-[700px] text-5xl"
                    : "top-1/2 w-4/5 -translate-y-1/2 text-3xl",
                  "absolute truncate text-center text-white",
                )}
              >
                {orgInfo.org.welcomeText}
              </span>
            )}
            {orgInfo?.org?.welcomeImage && orgInfo?.org?.welcomeKind === 2 && (
              <div
                className={clsx(
                  isPC
                    ? "top-[100px] max-h-[180px] max-w-[700px]"
                    : "top-[45%] h-[18vh] max-h-[120px] w-4/5 -translate-y-1/2",
                  "absolute size-full overflow-hidden",
                )}
              >
                <img
                  src={makeStorageUrl(orgInfo?.org?.welcomeImage)}
                  className="size-full object-contain"
                />
              </div>
            )}
          </>
        )}
      </div>

      <div
        className={clsx(
          isPC
            ? "absolute left-1/2 top-[45vh] flex -translate-x-1/2 space-x-8 px-9 py-8"
            : "relative mx-4 mt-[-15vw] h-[300px] px-6 py-4",

          {
            "w-[80vw] min-w-[720px] h-[360px] max-w-screen-lg":
              isPC &&
              isArray(orgInfo?.org?.locations) &&
              orgInfo?.org?.locations?.length > 0,
            "w-[522px] h-[340px]":
              isPC &&
              (!isArray(orgInfo?.org?.locations) ||
                orgInfo?.org?.locations?.length === 0),
          },
          " z-[1] rounded-3xl bg-white",
        )}
      >
        <div
          className={clsx(
            isPC &&
              isArray(orgInfo?.org?.locations) &&
              orgInfo?.org?.locations?.length > 0
              ? "flex w-[50vw] min-w-[320px] max-w-[450px] flex-col gap-2 border-r border-[#EAECF3] pr-8"
              : "w-full",
          )}
        >
          <div className="border-b-text-4/20 mb-3 flex items-center border-b py-3">
            {Boolean(areaLabel) && (
              <>
                <div className="text-text-1 text-sm">{areaLabel}</div>
                {Boolean(areaLabel) && (
                  <div className="bg-text-4 mx-4 h-6 w-px" />
                )}
              </>
            )}

            <div
              className="group flex flex-1 cursor-pointer items-center"
              onClick={showSearchPopup}
            >
              <IconSearch className="mr-2 size-5" />
              {address ? (
                <div className="text-text-1 line-clamp-1 flex-1 text-ellipsis break-all">
                  {address.name}
                </div>
              ) : (
                <div className="text-text-4 text-base">
                  搜索附近的地区或车站
                </div>
              )}
              <CancelIcon
                className={clsx(
                  { "group-hover:block": address },
                  "hidden size-4 cursor-pointer",
                )}
                onClick={(e) => deleteAddress(e)}
              />
            </div>
          </div>

          <LodgingDates value={occupancy} onChange={setOccupancy} />
          <TenantCount
            value={adultCount}
            onChange={setAdultCount}
            className="border-b"
          />

          <div className="mt-9 px-12">
            <button
              type="button"
              className="hover:bg-text-333 w-full rounded-3xl bg-[#3D3D3D] py-3 text-center text-sm font-medium text-white"
              onClick={onSearch}
            >
              搜索
            </button>
          </div>
        </div>
        {isPC && (
          <HotPlace
            isPC={isPC}
            data={orgInfo?.org?.locations}
            onChooseHotPlace={onChooseHotPlace}
          />
        )}
      </div>
      {!isPC && (
        <HotPlace
          isPC={isPC}
          data={orgInfo?.org?.locations}
          onChooseHotPlace={onChooseHotPlace}
        />
      )}
    </div>
  );
};

const HotPlace: React.FC<{
  data: OrgConfig.PopularLocation[] | undefined;
  isPC: boolean;
  onChooseHotPlace: (item: OrgConfig.PopularLocation) => void;
}> = ({ data, isPC, onChooseHotPlace }) => {
  if (data?.length === 0) return null;
  return (
    <div
      className={clsx(isPC ? "w-[50vw] min-w-[320px] max-w-[450px]" : "mx-4")}
    >
      <div
        className={clsx(
          isPC ? "mb-6 " : "mb-3 mt-6",
          "text-text-1 text-base font-medium",
        )}
      >
        热门地点
      </div>
      <div
        className={clsx(
          {
            "max-h-[260px]": isPC,
          },
          "flex w-full flex-wrap justify-start overflow-y-auto  pb-20",
        )}
      >
        {data?.map((item: OrgConfig.PopularLocation) => (
          <div
            key={item.name}
            className="active:bg-easbnb-brand mb-3 mr-3 cursor-pointer rounded-md border px-4 py-1.5 text-xs hover:bg-[#F5F7F9] active:text-white"
            onClick={() => {
              onChooseHotPlace(item);
            }}
          >
            <div className="text-sm">{item.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
