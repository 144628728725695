/** @format */

// This is code generated automatically by the proto2api, please do not modify

export enum Area {
  // 未知
  UnknownArea = 0,
  // 美国
  US = 1,
  // 日本
  JP = 2,
  // 中国
  CN = 3,
}
export enum Language {
  // 英语
  en = 0,
  // 日本语
  ja = 1,
  // 中文
  zh_CN = 2,
}
export enum Currency {
  // 未知
  UnknownCurrency = 0,
  // 美元
  USD = 1,
  // 日元
  JPY = 2,
  // 人民币
  CNY = 3,
}
// 实体枚举
export enum EntityKind {
  // 未知
  UnknownEntityKind = 0,
  // 房间
  Room = 4,
  // 微店背景图
  ResvBackground = 21,
  // 微店协议
  ResvContract = 22,
}
export enum PayKind {
  UnknownPayKind = 0,
  // 支付宝
  AliPay = 1,
  // 微信
  WechatPay = 2,
  // 信用卡
  Credit = 3,
}
