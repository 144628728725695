import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Dialog, Popup, Toast } from "react-vant";
import { useState } from "react";
import { useRequest } from "ahooks";
import clsx from "clsx";
import { RoomService } from "$/api_out/http_resv/room/room_srv";
import type * as room_srv from "$/api_out/http_resv/room/room_srv";
import { SEARCH_RESULT_ICON } from "@/constants/icon";
import IconSearch from "@/assets/icons/search.svg?react";
import EmptySearch from "@/assets/images/empty_search.svg?react";
import LoadingSearch from "@/assets/icons/search_loading.svg?react";
import CancelIcon from "@/assets/icons/cancel.svg?react";
import { useSearchHistory } from "@/hooks/useSearchHistory";
import { TextHighLight } from "@/components/TextHighlight";
import { useIsPC } from "@/stores";

export const SearchPopup = NiceModal.create(() => {
  const modal = useModal();
  const isPC = useIsPC();

  const { historyList, pushHistory, clearHistory } = useSearchHistory();
  const [search, setSearch] = useState("");
  const [isComposing, setIsComposing] = useState(false);

  const { loading, run, data, mutate } = useRequest(
    async () => {
      if (!search) {
        return;
      }
      const resp = await RoomService.ListLocation({
        keyword: search,
      });

      return resp.locations || [];
    },
    {
      debounceWait: 500,
      ready: !isComposing,
      refreshDeps: [search],
    },
  );

  const onResultClick = async (result: room_srv.ListLocationResp.Location) => {
    // 点击搜索结果后，把当前搜索的内容放到搜索历史中

    pushHistory(result.name);
    setSearch("");
    modal.resolve({ ...result, address: result.name });
    await modal.hide();
  };

  const onClose = async () => {
    setSearch("");
    await modal.hide();
  };

  const onSearchHistoryClick = (item: string) => {
    setSearch(item);
    run();
  };

  const showClearHistoryConfirmModal = () => {
    void Dialog.confirm({
      message: "删除全部搜索历史？",
      confirmButtonText: "全部删除",
      confirmButtonColor: "var(--wd-color-easbnb-brand)",
      onConfirm: () => {
        clearHistory();
        Toast({
          message: "已删除",
        });
      },
    });
  };

  return (
    <Popup
      visible={modal.visible}
      closeable={true}
      className={clsx(isPC ? "h-[520px] w-[500px]" : "h-4/5")}
      position={isPC ? "center" : "bottom"}
      round={true}
      destroyOnClose={true}
      onClose={async () => {
        await onClose();
      }}
      onClosed={() => {
        modal.remove();
      }}
    >
      <div className="pb-safe-area-bottom flex h-full flex-col">
        <div className="border-b-text-4/20 mx-4 mb-3 mt-4 flex justify-center border-b pb-2 text-lg font-medium">
          搜索
        </div>
        <div className="group mx-4 mb-2 flex items-center space-x-2 rounded-lg bg-[#f8f8f9] px-3 py-2.5">
          <IconSearch className="size-4" />
          <input
            placeholder="搜索附近的地区或车站"
            className="text-text-1 placeholder:text-text-4 w-full flex-1 bg-transparent text-sm"
            autoCapitalize="none"
            autoComplete="off"
            value={search}
            onCompositionEnd={() => setIsComposing(false)}
            onCompositionStart={() => setIsComposing(true)}
            onChange={(e) => setSearch(e.target.value)}
          />
          {loading && search && (
            <LoadingSearch className="size-4 animate-spin" />
          )}
          <CancelIcon
            className={clsx(
              { "group-hover:block": search.length > 0 && !loading },
              "hidden size-4 cursor-pointer",
            )}
            onClick={(e) => {
              e.stopPropagation();
              mutate([]);
              setSearch("");
            }}
          />
        </div>
        <div className="flex flex-1 flex-col overflow-y-auto">
          {/* 历史搜索 */}
          {!search && historyList.length > 0 && (
            <div className="mx-4 flex flex-col">
              <div className="text-text-1 mb-3 flex justify-between text-base font-medium">
                <span>历史搜索</span>
                <span
                  className="text-text-4 cursor-pointer text-sm font-normal"
                  onClick={showClearHistoryConfirmModal}
                >
                  清除历史
                </span>
              </div>

              <div className="flex flex-wrap justify-start gap-x-3">
                {historyList.map((item) => (
                  <div
                    className="bg-page-bg text-text-2 mb-3 cursor-pointer truncate rounded-lg px-4 py-1.5 text-center text-sm"
                    key={item}
                    onClick={() => onSearchHistoryClick(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* 搜索结果列表 */}
          {data && Boolean(data.length) && search && (
            <div className="flex w-full flex-col ">
              {data.map((item, idx) => (
                <div
                  key={idx}
                  className="border-b-text-4/20 flex cursor-pointer flex-col border-b px-4 py-3 hover:bg-[#F5F7F9]"
                  onClick={() => onResultClick(item)}
                >
                  <div className="text-text-1 line-clamp-2 flex items-center break-all text-base font-medium">
                    <img
                      src={SEARCH_RESULT_ICON[item.locationKind].icon}
                      className="mr-2 size-4"
                    />
                    <TextHighLight text={item.name} keyword={search} />
                  </div>
                  <div className="text-text-2 line-clamp-1 text-sm">
                    {item.address}
                  </div>
                </div>
              ))}
            </div>
          )}

          {/* 没有搜索结果 */}
          {data?.length === 0 && !loading && search && (
            <div className="flex flex-col items-center pt-24">
              <EmptySearch />
              <div className="text-text-4 mt-3 text-sm">暂无搜索结果</div>
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
});
