import { type Room } from "$/api_out/http_resv/room/room_type";
import { PLACEHOLDER_TEXT } from "@/constants/text";

export const getRoomInfo = ({ room }: { room: Room }) => {
  // 面积
  const areaText = `${room.roomSize || PLACEHOLDER_TEXT}㎡`;
  // 单人床
  const bedText = room.singleBedCount ? `${room.singleBedCount}张单人床` : "";
  // 双人床
  const doubleBedText = room.doubleBedCount
    ? `${room.doubleBedCount}张双人床`
    : "";

  // 单人床 双人床 数量为0或者不存在的不显示
  const roomInfoText = [areaText, bedText, doubleBedText]
    .filter((el) => Boolean(el))
    .join(" · ");

  return roomInfoText;
};

export const getDistanceInfo = (distanceNum: number | string) => {
  if (typeof distanceNum === "string" || !distanceNum) {
    return "";
  }
  let distanceText;

  const distanceNumKm = distanceNum / 1000;

  if (distanceNumKm < 1) {
    distanceText = `${distanceNum}m`;
  } else if (distanceNumKm >= 1 && distanceNumKm < 100) {
    distanceText = `${distanceNumKm.toFixed(1)}km`;
  } else {
    distanceText = `100km+`;
  }

  return distanceText;
};
