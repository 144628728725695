import { Skeleton } from "react-vant";
import { styled } from "styled-components";

export const CustomSkeleton = styled(Skeleton)<{ $color?: string }>`
  .rv-skeleton {
    padding: 0 !important;
  }
  .rv-skeleton__row {
    background-color: ${(p) => p.$color ?? "#f2f3f5"};
  }
`;
