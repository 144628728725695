import clsx, { type ClassValue } from "clsx";
import dayjs, { type Dayjs } from "dayjs";
import { Toast } from "react-vant";
import { twMerge } from "tailwind-merge";
import queryString from "query-string";
import { isNaN, isNumber, isString } from "lodash-es";

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

// 获取日期的月日: 03-04
export const toMMDD = (
  date: Date | Dayjs | string,
  options?: { ignoreThisYear?: boolean; chinese?: boolean; splitStr?: string },
) => {
  const {
    ignoreThisYear = true,
    chinese = false,
    splitStr = "-",
  } = options || {};
  const isThisYear = dayjs(date).isSame(dayjs(), "years");

  let format = `MM${splitStr}DD`;

  if (isThisYear && ignoreThisYear) {
    if (chinese) {
      format = "MM月DD日";
    } else {
      format = `MM${splitStr}DD`;
    }
  } else if (chinese) {
    format = "YYYY年MM月DD日";
  } else {
    format = `YYYY${splitStr}MM${splitStr}DD`;
  }

  return dayjs(date).format(format);
};

// 获取日期的年月: 2023-03
export const toYYYYMM = (date: Date | Dayjs | string) => {
  if (date) {
    return dayjs(date).format("YYYY-MM");
  }
  return "";
};

// 获取日期的年月: 2023-03-16
export const toYYYYMMDD = (date: Date | Dayjs | string | undefined) => {
  if (date) {
    return dayjs(date).format("YYYY-MM-DD");
  }
  return "";
};

export const toYYYYMMDDHHmmss = (date: Date | Dayjs | string) => {
  if (date) {
    return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
  }
  return "";
};

export const getWeekDay = (date: Dayjs) => {
  const Weekdays = {
    0: "周日",
    1: "周一",
    2: "周二",
    3: "周三",
    4: "周四",
    5: "周五",
    6: "周六",
  };
  return Weekdays[date.day()];
};

// 是否为住宿行业的周末(住宿行业的周末是星期五和星期六)
export const isHotelIndustryWeekend = (date: Date | Dayjs) => {
  const day = dayjs(date).day();
  return day === 5 || day === 6;
};

export const isToday = (date: Date | Dayjs) => {
  const today = dayjs();
  return dayjs(date).isSame(today, "day");
};

export const copyToClipboard = (text: string) => {
  // 实测 Clipboard API 在 iPhone 上不支持
  if (navigator.clipboard) {
    navigator.clipboard
      // 去除首尾空白字符
      .writeText(text?.trim())
      .then(() => {
        Toast({
          type: "success",
          message: "已复制",
        });
      })
      .catch((error) => {
        Toast({
          type: "fail",
          message: `复制失败：${error.message}`,
        });
      });
  } else {
    // 以下代码来自：https://www.zhangxinxu.com/wordpress/2021/10/js-copy-paste-clipboard/
    const textarea = document.createElement("textarea");
    document.body.appendChild(textarea);
    // 隐藏此输入框
    textarea.style.position = "fixed";
    textarea.style.clip = "rect(0 0 0 0)";
    textarea.style.top = "10px";
    // 赋值，手动去除首尾空白字符
    textarea.value = text?.trim();
    // 选中
    textarea.select();
    // 复制
    document.execCommand("copy", true);
    Toast({
      type: "success",
      message: "已复制",
    });
    // 移除输入框
    document.body.removeChild(textarea);
  }
};

export const stringify = (obj: Record<string, any>) =>
  queryString.stringify(obj);

export const parseSearchStr = (str: string) => queryString.parse(str);

// 转换为数字，否则返回 undefined
export const parseToNumber = (v: any): number | undefined => {
  if (isNumber(v) && !isNaN(v)) {
    return v;
  }
  if (isString(v)) {
    const res = Number(v);
    if (!isNaN(res)) {
      return res;
    }
  }
  return undefined;
};

export const customDateFormat = (dateString: string) => {
  const date = new Date(dateString);
  return dayjs(date).format("YYYY-MM-DD");
};
