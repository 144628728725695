import clsx from "clsx";
import { type RoomInDetail } from "$/api_out/http_resv/room/room_type";
import { Card } from "@/components";
import { useIsPC } from "@/stores";
import { getRoomInfo } from "@/utils/roomInfo";
import { RentDiscount } from "@/pages/Room/List/components/RentDiscount";

interface TitleCardProps {
  data: RoomInDetail;
}

export const TitleCard: React.FC<TitleCardProps> = ({ data }) => {
  const isPC = useIsPC();
  const roomInfoText = getRoomInfo({
    room: data.room,
  });

  return (
    <>
      {isPC ? (
        <Card className="mb-3 px-6 py-4">
          <TitleInfo data={data} roomInfoText={roomInfoText} />
        </Card>
      ) : (
        <div>
          <TitleInfo data={data} roomInfoText={roomInfoText} />
        </div>
      )}
    </>
  );
};

const TitleInfo: React.FC<{ data: RoomInDetail; roomInfoText: string }> = ({
  data,
  roomInfoText,
}) => {
  const isPC = useIsPC();

  return (
    <div className={clsx({ "mb-2": !isPC })}>
      <div className={clsx("text-text-1 text-xl font-medium")}>
        {data?.room.title}
      </div>
      <div className={clsx({ "flex space-x-3": isPC })}>
        <RentDiscount discounts={data.room.discounts} />

        {data.room.roomSize || data.room.personCapacity ? (
          <div className="mb-3 mt-2 flex items-center space-x-2">
            {data.room.roomSize ? (
              <span className="text-text-666 text-sm">{roomInfoText}</span>
            ) : null}
            {data.room.personCapacity ? (
              <span className="text-easbnb-brand text-sm">
                可住{data?.room.personCapacity}人
              </span>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};
