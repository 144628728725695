import { Image, ImagePreview, NavBar } from "react-vant";
import { Helmet } from "react-helmet";
import { useRequest } from "ahooks";
import { RoomService } from "$/api_out/http_resv/room/room_srv";
import clsx from "clsx";
import { useLocation, useParams } from "react-router-dom";
import { useCustomNavigate, useResvInfo, useSwiperData } from "@/hooks";
import { genBigRoomSwiperCoverUrl, makeStorageUrl } from "@/utils/file";
import {
  type ImagesGroupItem,
  type InitImageProps,
} from "@/components/RoomSwiper";
import { genPageTitle } from "@/utils";
import { PATHS } from "@/constants/path";
import { ErrorComp, FixedHeaderWrapper, Loading } from "@/components";
import { useIsPC } from "@/stores";

export const RoomPhoto = () => {
  const customNavigate = useCustomNavigate();
  const isPC = useIsPC();
  const params = useParams<{ id?: string }>();
  const id = params.id;
  const location = useLocation();

  const { occupancy, adultCount } = useResvInfo();
  const { data, error, loading } = useRequest(async () => {
    if (location.state?.data) {
      return location.state.data as InitImageProps[];
    }
    const resp = await RoomService.Get({
      id: id!,
      priceQuery: {
        startDate: occupancy?.startDate,
        endDate: occupancy?.endDate,
        adultCount,
      },
    });

    return resp.room.images;
  });

  const groupPhotoData = useSwiperData(
    (data as InitImageProps[]) ?? [],
  ) as ImagesGroupItem[];

  const onTabClick = (item: ImagesGroupItem) => {
    const el = document.getElementById(item.label);
    if (el) {
      const height = el.offsetTop - 94;
      window.scrollTo({
        top: height,
        behavior: "smooth",
      });
    }
  };

  if (error) {
    return <ErrorComp error={error} />;
  }

  return (
    <div>
      <Helmet>
        <title>{genPageTitle("房间相册")}</title>
      </Helmet>
      {isPC && (
        <FixedHeaderWrapper
          pageName="房间相册"
          goBack={() => customNavigate(PATHS.ROOM, { id })}
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <div>
          {!isPC && (
            <NavBar
              title="房间相册"
              leftText="返回"
              placeholder={true}
              fixed={true}
              zIndex={50}
              onClickLeft={() => {
                customNavigate(PATHS.ROOM, {
                  id,
                });
              }}
            />
          )}
          <div className="flex flex-col bg-[#fafafa]">
            {!isPC && (
              <div className="bg-page-bg no-scrollbar fixed z-50 flex h-12 w-full items-center overflow-x-auto px-4">
                <div className="no-scrollbar inline-block w-full space-x-3 overflow-y-hidden whitespace-nowrap">
                  {groupPhotoData.map((item: ImagesGroupItem, index) => (
                    <div
                      key={item.name}
                      onClick={() => {
                        onTabClick(item);
                      }}
                      className={clsx(
                        "text-text-2 active:bg-easbnb-brand inline-block h-6 cursor-pointer rounded-lg bg-[#F0F2F4] px-2 py-0.5 text-sm active:text-white",
                        // 第一个元素不给左边距
                        index === 0 && "ml-0",
                      )}
                    >
                      {item.name}&nbsp;({item.images.length})
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="mb-4">
              {groupPhotoData.map((item: ImagesGroupItem) => (
                <PhotoGroup
                  data={item}
                  key={item.name}
                  onPreview={(index: number) => {
                    ImagePreview.open({
                      lazyload: true,
                      images: data?.map((image) => makeStorageUrl(image.uri)),
                      startPosition: index,
                      closeable: true,
                    });
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const PhotoGroup: React.FC<{
  data: ImagesGroupItem;
  onPreview: (index: number) => void;
}> = ({ onPreview, data }) => {
  const isPC = useIsPC();
  return (
    <div
      id={data.label}
      className={clsx(
        isPC ? "top-4 mx-auto w-[80vw] max-w-screen-lg" : "top-12 px-4",
        "bg-page-bg relative grow overflow-auto ",
      )}
    >
      <span>
        {data.name}&nbsp;({data.images.length})
      </span>
      <div className="mb-6 mt-3">
        <div
          className={clsx(
            isPC ? "flex flex-wrap gap-4" : "grid grid-cols-2 gap-3",
            " w-full ",
          )}
        >
          {data.images.map((item) => (
            <div
              key={item.index}
              className={clsx({
                "h-[120px] w-[192px]": isPC,
              })}
            >
              <Image
                src={genBigRoomSwiperCoverUrl(item.uri)}
                fit="cover"
                className="aspect-video cursor-pointer"
                radius="8px"
                // TODO: 有没有办法让图片自动加载
                // lazyload={true}
                onClick={() => {
                  onPreview(item.index);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
