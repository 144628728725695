/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { type EntityKind, type Currency } from "../const/const_enum";

export interface Tenant {
  // string id          = 1;  // 用户名
  name: string;
  // 邮箱
  email: string;
  // 国家代号
  countryCode: string;
  // 手机号
  phoneNumber: string;
}

export interface PriceQuery {
  startDate?: string;
  endDate?: string;
  // 人数 @uint32
  adultCount: number;
}

export interface Point {
  // 经度 @float
  lng: number;
  // 纬度 @float
  lat: number;
}

export interface Image {
  partition: PartitionKind;
  uri: string;
  // desc @int32
  rank: number;
}

// 实体信息
export interface EntityInfo {
  // 实体
  entityKind: EntityKind;
  // 对应实体的id
  relationId: string;
  // 日期备注的情况下 subId就是年月日
  relationSubId: string;
}

export interface CurrencyAmount {
  // 结算币种
  currency: Currency;
  // 金额 @int32
  amount: number;
}
export enum PartitionKind {
  // default 默认 除房间外其他entity默认的分区
  Partition_0 = 0,
  Partition_1 = 1,
  Partition_2 = 2,
  Partition_3 = 3,
  Partition_4 = 4,
  Partition_5 = 5,
  Partition_6 = 6,
  Partition_7 = 7,
  // 自定义
  Bedroom = 4001,
  // 客厅
  Parlor = 4002,
  // 厨房
  Kitchen = 4003,
  // 卫浴
  Bathroom = 4004,
  // 外观
  Exterior = 4005,
  // 其他
  Other = 4006,
}
export enum DiscountKind {
  Unknown_Discount = 0,
  // 周租优惠
  WeeklyDiscount = 1,
  // 月租优惠
  MonthlyDiscount = 2,
}
