import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Popup } from "react-vant";
import clsx from "clsx";
import { useIsPC } from "@/stores";

export const PrivacyPopup = NiceModal.create(() => {
  const modal = useModal();
  const isPC = useIsPC();

  return (
    <Popup
      visible={modal.visible}
      closeable={true}
      className={clsx(isPC ? "h-[460px] w-[640px]" : "h-4/5")}
      position={isPC ? "center" : "bottom"}
      round={true}
      destroyOnClose={true}
      onClose={async () => {
        await modal.hide();
      }}
      onClosed={() => {
        modal.remove();
      }}
    >
      <div className="flex h-full flex-col bg-white pb-9">
        <div className="border-b-text-4/20 mx-4 mt-4 flex justify-center border-b pb-2 text-lg font-medium">
          隐私政策
        </div>
        <div className="no-scrollbar h-full overflow-y-scroll px-6 pt-4">
          <h1 className="text-base font-medium">声明：</h1>
          <div className="text-sm">
            <br />
            本协议由您与EasIt株式会社(下文简称“EasIt”)共同缔结，具有合同效力。在使用“EasInn民宿”
            (包括“EasInn民宿”APP及easinn.com，以下简称“EasInn”)各项服务前，请您务必仔细阅读并透彻理解本协议。如果您使用“EasInn”服务，您的使用行为将被视为对本协议全部内容的认可。若本协议内任何一则条款由于国家或地方政策变更而不再具有约束力，该条款的失效不对协议内其他条款的继续履行造成任何影响。
            <br />
            <br />
            一、协议的效力
            <br />
            <br />
            1．本协议中的“EasInn”隶属EasIt株式会社，并根据相关法律、法规为房屋经营者及房客提供在线交易的技术服务平台。其中房屋经营者包括酒店类、非标住宿类（包括但不限于民宿、客栈等）业务的经营者。
            <br />
            <br />
            2．协议中“您”、“用户”，指进入、访问、使用“EasInn”网站(easinn.com)，或通过其它可实现“EasInn”服务的网址和移动设备应用获取“EasInn”服务的用户。
            <br />
            <br />
            3．本协议内容包括协议正文及所有“EasInn”已经发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。除另行明确声明外，任何“EasInn”及其关联公司提供的服务(以下称为“EasInn服务”)均受本协议约束。
            <br />
            <br />
            4．使用“EasInn”服务，您应当认真、完整阅读本协议，并承诺接受并遵守本协议的约定。如您不同意本协议的约定，您应立即停止注册程序或停止使用“EasInn”服务。只要您使用“EasInn”服务，即视为您与“EasInn”缔结本协议，本协议即对您产生约束，届时您不应以未阅读本协议的内容或者未获得“EasInn”对您问询的解答等理由，主张本协议无效，或要求撤销本协议。
            <br />
            <br />
            5．“EasInn”保留根据相关法律法规规定修订服务协议的权利。在使用“EasInn”服务时，您应当关注协议修订的公示，并不时回顾“EasInn”服务协议条款进行仔细阅读和重新确认。如您继续使用“EasInn”服务，即表示您接受已经修订的协议。如您不同意相关变更，应当立即停止使用“EasInn”服务。
            <br />
            <br />
            6．对于您提供的“EasInn”资料及数据信息，您授予“EasInn”非独家的、全球通用的、免费的许可使用权利。您同意，“EasInn”有权（全部或部分地）使用、复制、修订、发布、翻译、执行和展示您的资料数据(包括但不限于注册资料、行为数据及全部展示于“EasInn”平台的各类信息)或制作其派生作品，并以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入其它作品内。
            <br />
            <br />
            7．您接受并使用“EasInn”各项服务时，除遵守本协议外，仍需遵守此服务其它各类相关规则，且您的使用行为受其约束。
            <br />
            <br />
            8．本协议及其未来变更后的协议和规则按照相关法律法规在网站公布期满后，立即自动生效。
            <br />
            <br />
            二、注册会员
            <br />
            <br />
            依照本协议要求，于“EasInn”登记注册的会员（亦称“注册用户”），需同意以下服务条款，方有资格享受“EasInn”提供的相应服务，并受本协议条款的约束。
            <br />
            <br />
            1．注册账户 为使用“EasInn”向您的提供的服务，您必须：
            1）自行配备上网所必需设备，包括计算机、调制解调器或其他必备上网装置；
            2）自行承担电话费用、网络费用，以及在使用服务过程中形成的其他一切费用。
            您确认，在您完成注册程序或以其他“EasInn”允许的方式实际使用“EasInn”服务时，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织。若您不具备前述主体资格，则您及您的监护人应承担因此而导致的一切后果，且“EasInn”有权注销（永久冻结）您的“EasInn”账户，给“EasInn”造成损失时，“EasInn”将向您及您的监护人索偿。
            “EasInn”建议，任何未成年人参加网上活动应事先取得其监护人(以下简称“监护人”)的同意并遵守《青少年网络文明公约》。
            “EasInn”提供的服务将完全按照其发布的章程、服务条款和操作规则严格执行。您在确认并接受“EasInn”服务协议，您完全完成注册程序或以其他“EasInn”允许的方式实际使用“EasInn”服务时，便成为“EasInn”的注册用户，将得到唯一的“EasInn”账户，(以下亦称“账户”)。
            您需对您的“EasInn”账户设置用户名和密码，通过该用户名密码或与该用户名密码关联的其它用户名密码登陆“EasInn”网站(easinn.com)
            、“EasInn”APP或其它可实现“EasInn”服务的网址和移动设备应用。您设置的用户名不得侵犯或涉嫌侵犯他人合法权益，不得违反法律，不得违背公序良俗。
            为保障您的合法权益，避免在享受服务时因用户注册资料与真实情况不符而发生纠纷，注册时，您应当按照法律法规要求，或注册页面的提示，准确填写并及时更新您提供的电子邮件地址、联系电话、联系地址等联系方式及其它注册资料，以便“EasInn”或其他会员与您进行有效联系。因通过这些联系方式无法与您取得联系，导致您在使用“EasInn”服务过程中产生任何损失或增加费用的，应由您完全独自承担。如您的资料发生变更，您应及时更新您的资料，以使之真实、及时、完整和准确。若您提供的资料存在任何错误、不实、过时、不完整的情况，或者“EasInn”有任何合理理由认为您的资料存在上述问题，造成难以提供服务的，“EasInn”有权向您发出询问及/或要求改正的通知，您应及时向“EasInn”反馈有关情况，否则“EasInn”有权中止、终止对您提供部分或全部“EasInn”服务。
            “EasInn”对此不承担任何责任，您将承担因此产生的任何直接或间接支出。
            <br />
            <br />
            2．会员
            在您按照注册页面提示填写信息、阅读并同意本协议并完成全部注册程序后或以其他“EasInn”允许的方式实际使用“EasInn”服务时，您即成为“EasInn”会员(亦称“会员”、“注册用户”)。
            您有权随时对自己的个人资料进行查询、修改和删除。为客户服务安全考虑，用户名不能随意更改。
            您有权注销账户，具体操作请详见《隐私保护声明》。
            “EasInn”的登陆用户名及密码只供会员使用，会员有责任维护其用户名和密码的保密性和安全性。您应对您的用户名和密码的安全，以及对通过您的用户名和密码实施的行为承担全部责任。若您发现用户名和密码丢失、被盗，或任何人未经取得同意不当使用您的账户或有任何其他可能危及您账户安全的情形时，您应当立即以有效方式通知“EasInn”，要求“EasInn”暂停相关服务。因会员方造成的账户失密，应由会员承担直接或间接的责任，“EasInn”对其产生的后果（包括但不限于您的任何损失）不承担任何责任。如“EasInn”监测到非授权用户使用会员用户名及密码登录本站，“EasInn”有权暂停或取消此账号登录权限，并对失密原因进行调查。
            使用“EasInn”服务时，您必须遵守《中华人民共和国保密法》、《中华人民共和国著作权法》、《计算机信息系统国际联网保密管理规定》、《中华人民共和国计算机信息系统安全保护条例》、《计算机信息网络国际联网安全保护管理办法》、《中华人民共和国计算机信息网络国际联网管理暂行规定》及其实施办法等相关法律法规的任何及所有的规定。如“EasInn”有任何合理理由认为您的行为可能违反相关法律、法规、规章及条例，“EasInn”有权在任何时间，不经事先通知终止或中止向您提供服务。
            您了解并同意，“EasInn”有权应政府部门（包括司法及行政部门）的要求，向其提供您在“EasInn”填写的注册信息和发布记录等必要信息。
            <br />
            <br />
            三、 “EasInn”服务说明
            <br />
            <br />
            1．“EasInn”专注于为房屋经营者和有短期住宿需求的房客提供一个在线交易的技术服务平台，供免费信息发布和在线沟通交流。有别于传统的房屋租赁公司或网站，“EasInn”致力于打造专业的酒店、民宿/客栈网络预订交易服务等，包括发布房屋出租信息、酒店、民宿/客栈预订信息、在线信息交流工具及第三方支付服务（以下简称“本服务”）。
            <br />
            <br />
            2．本网站各项服务的所有权和运营权归“EasInn”拥有。除非本服务条款另有其他明示规定，增加或强化目前本服务的任何新功能，包括所推出的新产品，均受到本服务协议之规范。
            <br />
            <br />
            3．“EasInn”仅为用户提供展示房屋租赁、酒店、民宿/客栈预订的信息平台。用户了解且明白，对于“EasInn”上房屋经营者自由发布的所有信息，尽管“EasInn”在该等信息发布之前已对信息的准确性、真实性和合法性作了必要核查，但用户务必自行核查发布信息的准确性、真实性以及合法性。“EasInn”并不对所发布信息的准确性、真实性和合法性承担保证责任。如用户发现该等发布的信息为虚假、违法信息，请立即向“EasInn”举报，“EasInn”将采取删除与屏蔽，以及其他相应的技术和法律手段阻止此类信息的发布。“EasInn”不对用户所发布的信息的删除或储存失败负责。
            <br />
            <br />
            4．用户通过“EasInn”获取住宿服务信息后，在线下与房屋经营者或房客达成住宿服务交易时，请务必遵守中国相关法律法规，查验双方有权出租、承租房屋及其合法有效的身份证明等相关文件，或是否有资质从事住宿服务，并签订书面协议以保护自身利益。“EasInn”不对用户在线下的交易行为引起的任何性质的纠纷负有任何性质的协助义务或承担任何形式的责任。用户应当通过法律途径自行解决其与房屋经营者或房客因此而产生的任何纠纷。
            <br />
            <br />
            5．用户了解并同意，本服务仅依其当前所呈现的状况提供，对于任何用户通讯或个人化设定之失效、删除、传递错误、未予储存或其他任何问题，“EasInn”均不承担任何责任。“EasInn”保留不经事先通知为维修保养、升级或其他目的暂停本服务任何部分的权利。
            <br />
            <br />
            四、订单的生效
            <br />
            <br />
            1．房屋经营者通过“EasInn”发布房间信息，其性质为要约邀请，是房屋经营者希望用户向其发出要约的意思表示；当用户预订房间并成功提交订单时，视为用户向房屋经营者发出了入住房间的要约；房屋经营者确认能够满足用户的入住需求，视为房屋经营者对用户的要约做出承诺，此时订单生效。
            <br />
            <br />
            2．”EasInn“预订页面显示的订单价格仅为房费，若房屋经营者通过“EasInn”以外的渠道（包括但不限于线下、其他第三方平台等）向您收取任何费用（如押金、保洁费用等），请您及时联系客服进行核实。
            <br />
            <br />
            3.
            “EasInn”平台内所有的优惠（包括但不限于折扣券、商品打折、满赠等行为）均由平台上的房屋经营者提供，具体优惠活动包括但不限于新人9折券、学生专享8.8折券、生日8折券、分享助力8折优惠券等，优惠的具体适用条件见各优惠具体规则。
            <br />
            <br />
            五、用户管理
            <br />
            <br />
            1．在“EasInn”上使用“EasInn”服务过程中，您承诺遵守以下约定：
            1）在使用“EasInn”服务过程中遵守国家法律、法规等规范文件及“EasInn”各项规则的规定和要求，不违背社会公共利益或公共道德，不损害他人的合法权益，不违反本协议及相关规则。您如果违反前述承诺，产生任何法律后果的，您应以自己的名义独立承担所有的法律责任，并确保“EasInn”免于因此产生任何损失。
            2）不发布国家禁止发布的信息（除非取得合法且足够的许可），不发布涉嫌侵犯他人知识产权或其它合法权益的信息，不发布违背社会公共利益或公共道德、公序良俗的信息，不发布其它涉嫌违法或违反本协议及各类规则的信息。
            3）不对“EasInn”网站上的任何数据作商业性利用，包括但不限于在未经“EasInn”事先书面同意的情况下，以复制、传播等任何方式使用“EasInn”上展示的资料。
            4）不使用任何装置、软件或例行程序干预或试图干预“EasInn”的正常运作或正在“EasInn”上进行的任何活动。您不得采取任何将导致不合理的庞大数据负载加诸“EasInn”网络设备的行动。
            5）不使用网络服务作非法用途； 6）不干扰或混乱网络服务；
            7）遵守所有使用网络服务的网络协议、规定、程序和惯例。
            <br />
            <br />
            2．您了解并同意：
            1）您违反上述承诺时，“EasInn”有权依据本协议的约定，做出相应处理或终止向您提供服务，且无须征得您的同意或提前通知予您。
            2）根据相关法令的指定或者“EasInn”服务规则的判断，您的行为涉嫌违反法律法规的规定或违反本协议和/或规则的条款的，“EasInn”有权采取相应措施，包括但不限于直接屏蔽、删除侵权信息、降低您的信用值或直接停止提供服务。
            3）对于您在“EasInn”实施的行为，包括您未在“EasInn”上实施但已经对“EasInn”及其用户产生影响的行为，“EasInn”有权单方认定您行为的性质及是否构成对本协议和/或规则的违反，并据此采取相应的处理措施。您应自行保存与您行为有关的全部证据，并应对无法提供充要证据而承担其不利后果。
            4）对于您涉嫌违反承诺的行为对任意第三方造成损害的，您均应当以自己的名义独立承担所有的法律责任，并应确保“EasInn”免于承担因此产生的损失或增加的费用。
            5）如您涉嫌违反有关法律或者本协议之规定，使“EasInn”遭受任何损失，或受到任何第三方的索赔，或受到任何行政管理部门的处罚，您应当赔偿“EasInn”因此造成的损失及（或）发生的费用，包括合理的律师费用。
            <br />
            <br />
            六、 “EasInn”使用规范
            <br />
            <br />
            1．您不得利用“EasInn”危害国家安全、泄露国家秘密，不得侵犯国家社会集体的和公民的合法权益，不得利用“EasInn”制作、复制和传播下列信息：
            1）煽动抗拒、破坏宪法和法律、行政法规实施的；
            2）煽动颠覆国家政权，推翻社会主义制度的；
            3）煽动分裂国家、破坏国家统一的；
            4）煽动民族仇恨、民族歧视，破坏民族团结的；
            5）捏造或者歪曲事实，散布谣言，扰乱社会秩序的；
            6）宣扬封建迷信、淫秽、色情、赌博、暴力、凶杀、恐怖、教唆犯罪的；
            7）公然侮辱他人或者捏造事实诽谤他人的，或者进行其他恶意攻击的；
            8）损害国家机关信誉的； 9）其他违反宪法和法律法规的；
            10）未经“EasInn”同意许可进行商业广告行为的。
            <br />
            <br />
            2．在接受“EasInn”服务的过程中，您不得从事下列行为：
            1）发表、传送、传播、储存侵害他人知识产权、商业秘密权等合法权利的内容。包含但不仅限于病毒、木马、定时炸弹等可能对“EasInn”系统造成伤害或影响其稳定性的内容；
            2）传送、散布或以其他方式实现传播含有受到知识产权法律保护的图像、相片、软件或其他资料的文件；
            3）上载、张贴、发送或传送任何非法、反动、淫秽、粗俗、猥亵的，胁迫、骚扰、中伤他人、诽谤、侵害他人隐私或诋毁他人名誉或商誉的，种族歧视、危害未成年人或其他不适当的信息或电子邮件，包括但不限于资讯、资料、文字、软件、音乐、照片、图形、信息或其他资料；
            4）上载、张贴、发送电子邮件或以其它方式传送无权传送的内容（例如内部资料、机密资料）；
            5）使用任何包含有通过侵犯商标、版权、专利、商业机密或任何一方的其他专有权利的方式，利用本“软件”获得图像或相片的资料或信息；
            6）进行危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/账号；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本平台系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏本平台系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；
            7）修改或伪造软件作品运行中的指令、数据、数据包，增加、删减、变动软件的功能或运行效果，不得将用于上述用途的软件通过信息网络向公众传播或者运营；
            8）在未经“EasInn”书面明确授权前提下，出售、出租、出借、散布、转移或转授权软件和服务或相关的链接或从使用软件和服务或软件和服务的条款中获利，无论以上使用是否为直接经济或金钱收益；
            9）违背“EasInn”页面公布之活动规则，包括但不限于发布虚假信息、作弊或通过其他手段进行虚假交易；
            10）冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为与任何人或任何机构有关；
            11）伪造标题或以其他方式操控识别资料，使人误认为该内容为“EasInn”所传送；
            12）跟踪或以其他方式骚扰他人；
            13）将在交易过程中获知的对方任何信息泄露给任何第三方、滥用其信息或未经对方同意以任何方式向其发送商业广告、骚扰信息等不良信息；
            14）其它被“EasInn”视为不适当的行为。
            <br />
            <br />
            3．
            “EasInn”声明用户的系统记录有可能作为用户违反法律及/或本协议的有效法律证据。“EasInn”有权在下述情况下，对内容进行保存或披露：
            1）法律程序所规定； 2）本服务条款规定；
            3）被侵害的第三人提出权利主张；
            4）为保护“EasInn”、其使用者及社会公众的权利、财产或人身安全；
            5）其他“EasInn”认为有必要的情况。
            <br />
            <br />
            七、知识产权保护
            <br />
            <br />
            1．网站和服务中的所有知识产权（包括著作权、专利、商标、服务标识、商号、设计，不论是否已登记注册）、网站中的信息内容或作为服务内容的信息、运营的数据库、所有网站设计、文字和图表、软件、照片、录像、音乐、声音及其前述组合，以及所有软件编译、相关源代码和软件（包括小应用程序和脚本）均为“EasInn”（或“EasInn”的许可方）所有。
            <br />
            <br />
            2．未经“EasInn”许可，任何人不得擅自（包括但不限于：以非法的方式复制、传播、展示、镜像、上载、下载）使用，亦不得以任何方式出售、出租或转租，用于制作改编作品、或以任何方式开发利用上述知识产权。否则，“EasInn”将依法追究其法律责任。
            <br />
            <br />
            3．网站页面上的文学和艺术作品的作者已主张其为该等作品的作者，从而享有相关的权利。
            <br />
            <br />
            八、隐私权政策
            <br />
            <br />
            除《隐私保护声明》中规定的几种个人信息将被披露的情形外，“EasInn”绝对尊重用户的个人隐私权，绝不会公开、编辑、披露用户的非公开资料给第三方（具体规则参见《隐私保护声明》）。
            <br />
            <br />
            九、协议终止
            <br />
            <br />
            1．您同意，“EasInn”基于平台服务的安全性、合法性，有权不经事先通知而中止、终止向您提供部分或全部“EasInn”服务，暂时冻结或永久冻结（注销）您的账户，且无须为此向您或任何第三方承担任何责任。如发生下列任何一种情形，“EasInn”有权单方面中止或终止向您提供服务而无需通知您：
            1）用户违反《中华人民共和国保守国家秘密法》、《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、信息产业部2000年10月8日第4次部务会议通过的《互联网电子公告服务管理规定》，以及《互联网新闻信息服务管理规定》等有关计算机及互联网规定的法律和法规、实施办法；
            2）用户违反本服务协议中规定的使用规则；
            3）注册信息中的主要内容不真实或不准确或不及时或不完整
            4）未经“EasInn”同意，将“EasInn”平台用于商业目的；
            5）“EasInn”终止向您提供服务后，您涉嫌再一次直接或间接或以他人名义注册为“EasInn”会员的；
            6）本协议（含规则）变更时，您明示并通知“EasInn”不愿接受新的服务协议的；
            7）其它“EasInn”认为应当终止服务的情况。
            <br />
            <br />
            2．您有权向“EasInn”要求注销您的账户，经“EasInn”审核同意的，“EasInn”注销您的账户，届时，您与“EasInn”基于本协议的合同关系即终止。您的账户被注销后，“EasInn”没有义务为您保留或向您披露您账户中的任何信息，也没有义务向您或第三方转发任何您未曾阅读或发送过的信息。
            <br />
            <br />
            3．您同意，您与“EasInn”的协议关系终止后，“EasInn”仍享有下列权利：
            1）根据有关法律法规的规定，继续保存您的注册信息及您使用“EasInn”服务期间发布的服务信息、交易信息；
            2）您在使用“EasInn”服务期间存在违法行为或违反本协议和/或规则的行为的，“EasInn”仍可依据本协议向您主张权利。
            <br />
            <br />
            4．“EasInn”中止或终止向您提供“EasInn”服务后，对于您在服务中止或终止之前的交易行为依下列原则处理，您应独立处理并完全承担进行以下处理所产生的任何争议、损失或增加的任何费用，并应确保“EasInn”免于因此产生任何损失或承担任何责任、费用：
            1）您在服务中止或终止之前已经上传至“EasInn”的房间尚未交易的，“EasInn”有权在中止或终止服务的同时删除此房间的相关信息；
            2）您在服务中止或终止之前已经与其他会员达成租住合同，但合同尚未实际履行的，“EasInn”有权删除此房间的相关信息，同时将相关情形通知您的交易对方；
            3）您在服务中止或终止之前已经与其他会员达成租住合同且已部分履行的，“EasInn”有权在中止或终止服务、删除此房间的相关信息，同时将相关情形通知您的交易对方。
            <br />
            <br />
            十、法律适用、管辖与其它
            <br />
            <br />
            1．本协议的理解与适用要与民宿所在地的法律解释一致。如无相关法律规定的，则应参照通用国际商业惯例和（或）行业惯例。
            <br />
            <br />
            2．若本协议中的条款与民宿所在地的法律相抵触，则按照法律规定进行解释，其他条款依旧保持其对双方的约束力。
            <br />
            <br />
            3．因本协议产生之争议，双方应尽力友好协商，协商不成时，任何一方均可向民宿所在地的管辖权的人民法院提起诉讼。
            <br />
            <br />
            4．了解到互联网的无国界性，您同意遵守当地所有适用的法规，包括但不限于关于网上行为及内容发布之法律法规。您特别同意遵守有关从您所在国家或地区输出技术数据之传输的所有适用法律法规。
          </div>
        </div>
      </div>
    </Popup>
  );
});
