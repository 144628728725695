import { Helmet } from "react-helmet";
import { useCountDown, useRequest } from "ahooks";
import { isNil, isUndefined, isEmpty } from "lodash-es";
import { NavBar } from "react-vant";
import { useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import NiceModal from "@ebay/nice-modal-react";
import { OrderService } from "$/api_out/http_resv/order/order_srv";
import {
  Loading,
  ErrorComp,
  FixedBottomWrapper,
  FixedHeaderWrapper,
  PriceDetailPopup,
  RentTag,
} from "@/components";
import ArrowRight from "@/assets/icons/arrow_right.svg?react";

import { useCustomNavigate } from "@/hooks";
import {
  RoomCard,
  CheckInOutCard,
  OrderStatusCard,
  AgreementCard,
} from "../components";
import { genPageTitle } from "@/utils";
import { makeStorageUrl } from "@/utils/file";
import { getOrderStatus, OrderStatusEnum } from "@/utils/order";
import { PATHS } from "@/constants/path";
import { useIsPC } from "@/stores";
import {
  CheckInMethodCard,
  PersonInfoCard,
  CheckOutPolicyCard,
  OrderInfoCard,
  PersonNoticeCard,
  DetailRoomCard,
} from "./components";
import { getMoneyText } from "@/utils/money";

export const OrderDetail = () => {
  const customNavigate = useCustomNavigate();
  const params = useParams<{ id?: string }>();
  const id = params.id;
  const [orderStatus, setOrderStatus] = useState<OrderStatusEnum>();
  const isPC = useIsPC();

  const { data, loading, refresh, error } = useRequest(
    async () => {
      const resp = await OrderService.Get({
        id: id!,
      });

      const countDownDate = resp.order.order.isCancelled
        ? undefined
        : Math.max(
            Number(resp?.order.order.cancelledAt) * 1000,
            Date.now() + 5000,
          );

      const status = getOrderStatus({
        dateStart: resp.order.order.startDate,
        dateEnd: resp.order.order.endDate,
        paidAt: resp.order.order.paidAt,
        isCancelled: resp.order.order.isCancelled,
      });

      setOrderStatus(status);
      return { ...resp.order, countDownDate };
    },
    {
      ready: !isUndefined(id),
    },
  );

  const [, formattedRes] = useCountDown({
    targetDate: data?.countDownDate,
    onEnd: () => {
      refresh();
    },
  });

  const onGoToPay = () => {
    if (isNil(data)) {
      return;
    }

    const path =
      data?.order.needSignContract && isEmpty(data?.order.signedAt)
        ? PATHS.ORDER_AGREEMENT
        : PATHS.PAY;

    customNavigate(path, {
      id: data.order.id,
      state: {
        isSigned: false,
      },
    });
  };

  if (!id) {
    return (
      <div className="flex h-[50vh] w-full items-center justify-center">
        <Helmet>
          <title>{genPageTitle("订单详情")}</title>
        </Helmet>
        <div className="text-text-4 text-sm">无法获取订单信息</div>
      </div>
    );
  }

  if (error) {
    return <ErrorComp refresh={refresh} error={error} />;
  }

  return (
    <div>
      <Helmet>
        <title>{genPageTitle("订单详情")}</title>
      </Helmet>

      {isPC && (
        <FixedHeaderWrapper
          pageName="订单详情"
          goBack={() => {
            customNavigate(PATHS.ORDER);
          }}
        />
      )}

      {loading ? (
        <Loading />
      ) : (
        <div>
          {!isPC && (
            <NavBar
              title="订单详情"
              leftText="返回"
              placeholder={true}
              zIndex={50}
              onClickLeft={() => {
                customNavigate(PATHS.ORDER);
              }}
            />
          )}
          <div className="bg-page-bg overflow-y-auto px-4 pb-8">
            {data && (
              <div
                className={clsx(
                  {
                    "w-[80vw] max-w-screen-lg min-w-[720px] ": isPC,
                  },
                  "mx-auto flex flex-col space-y-3 pt-3",
                )}
              >
                <OrderStatusCard
                  orderInDetail={data}
                  orderStatus={orderStatus}
                  formattedRes={formattedRes}
                />
                {isPC ? (
                  <>
                    <DetailRoomCard
                      room={data.room}
                      imageUri={makeStorageUrl(data.roomImageUri)}
                      price={{
                        currency: data.cost.currency,
                        amount: data.cost.payAmount,
                      }}
                      orderId={id}
                      disableLink={true}
                    />
                    <div className="flex space-x-4">
                      <div className="flex flex-1 flex-col space-y-4">
                        {Boolean(data?.order.needSignContract) && (
                          <AgreementCard
                            orderId={data?.order.id}
                            signedAt={data?.order.signedAt}
                            contractName={data?.order.contractName}
                          />
                        )}
                        <CheckInMethodCard data={data} />
                        <PersonNoticeCard data={data} />
                        <CheckOutPolicyCard data={data} />
                        <PersonInfoCard data={data} />
                      </div>
                      <div className="w-[360px] space-y-4">
                        <CheckInOutCard
                          startDate={data.order.startDate}
                          endDate={data.order.endDate}
                          adultCount={data.order.adultCount}
                        >
                          {isPC && (
                            <>
                              <div className="bg-page-bg text-text-666 flex flex-col items-center rounded-xl p-4 text-sm">
                                <div className="mb-3 flex w-full items-center justify-between border-b border-[#D2DDF8] pb-2">
                                  <span>订单金额</span>
                                  <div className="inline-flex items-center">
                                    <span className="text-danger text-2xl font-medium">
                                      {getMoneyText(
                                        data.cost.payAmount,
                                        data.cost.currency,
                                        {
                                          hidePlusSign: true,
                                        },
                                      )}
                                    </span>
                                    <RentTag
                                      discounts={data.cost.discounts}
                                      className="ml-1.5"
                                    />
                                  </div>
                                </div>
                                {data.cost.refundAmount ? (
                                  <div className="mb-3 flex w-full justify-between">
                                    <span>退款</span>
                                    <span>
                                      {getMoneyText(
                                        data.cost.refundAmount,
                                        data.cost.currency,
                                        {
                                          hideLabel: true,
                                          hidePlusSign: true,
                                        },
                                      )}
                                    </span>
                                  </div>
                                ) : null}
                                {data.cost.cleaningFee ? (
                                  <div className="mb-3 flex w-full justify-between">
                                    <span>清扫费</span>
                                    <span>
                                      {getMoneyText(
                                        data.cost.cleaningFee,
                                        data.cost.currency,
                                        {
                                          hideLabel: true,
                                          hidePlusSign: true,
                                        },
                                      )}
                                    </span>
                                  </div>
                                ) : null}
                                {data.cost.extraPersonPerAmount ? (
                                  <div className="mb-3 flex w-full justify-between">
                                    <span>额外人头费</span>
                                    <span>
                                      {getMoneyText(
                                        data.cost.extraPersonPerAmount,
                                        data.cost.currency,
                                        {
                                          hideLabel: true,
                                          hidePlusSign: true,
                                        },
                                      )}
                                    </span>
                                  </div>
                                ) : null}
                                <div
                                  onClick={async () => {
                                    await NiceModal.show(PriceDetailPopup, {
                                      cost: data.cost,
                                    });
                                  }}
                                  className="text-easbnb-brand flex cursor-pointer items-center text-xs"
                                >
                                  <span className="mr-2">费用明细</span>
                                  <ArrowRight />
                                </div>
                              </div>
                              {orderStatus === OrderStatusEnum.Unpaid && (
                                <div
                                  className="hover:bg-text-333 mt-6 cursor-pointer rounded-full bg-[#3D3D3D] px-20 py-3 text-center text-base font-medium text-white"
                                  onClick={onGoToPay}
                                >
                                  {data?.order.needSignContract &&
                                  (isEmpty(data?.order.signedAt) ||
                                    isUndefined(data?.order.signedAt))
                                    ? "签署协议并支付"
                                    : "去支付"}
                                </div>
                              )}
                            </>
                          )}
                        </CheckInOutCard>
                        <OrderInfoCard data={data} />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <RoomCard
                      data={data}
                      orderId={id}
                      disableLink={true}
                      imageUri={makeStorageUrl(data.roomImageUri)}
                    />
                    <CheckInOutCard
                      startDate={data.order.startDate}
                      endDate={data.order.endDate}
                      adultCount={data.order.adultCount}
                      cost={data.cost}
                      onClick={async () => {
                        await NiceModal.show(PriceDetailPopup, {
                          cost: data.cost,
                          modalOffset:
                            !isPC && orderStatus === OrderStatusEnum.Unpaid,
                        });
                      }}
                    />
                    {Boolean(data?.order.needSignContract) && (
                      <AgreementCard
                        orderId={data?.order.id}
                        signedAt={data?.order.signedAt}
                        contractName={data?.order.contractName}
                      />
                    )}

                    <CheckInMethodCard data={data} />
                    <PersonNoticeCard data={data} />
                    <CheckOutPolicyCard data={data} />
                    <PersonInfoCard data={data} />
                    <OrderInfoCard data={data} />
                  </>
                )}
              </div>
            )}

            {orderStatus === OrderStatusEnum.Unpaid && !isPC && (
              <FixedBottomWrapper className=" flex w-full items-center justify-center border-t border-[#E9F0FF] bg-white py-3">
                <button
                  type="button"
                  className="hover:bg-text-333 rounded-full bg-[#3D3D3D] px-20 py-3 text-base font-medium text-white"
                  onClick={onGoToPay}
                >
                  {data?.order.needSignContract &&
                  (isEmpty(data?.order.signedAt) ||
                    isUndefined(data?.order.signedAt))
                    ? "签署协议并支付"
                    : "去支付"}
                </button>
              </FixedBottomWrapper>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
