import dayjs from "dayjs";
import { isNaN } from "lodash-es";
import { type FC } from "react";
import clsx from "clsx";
import { Card } from "@/components/Card";
import PeopleIcon from "@/assets/icons/people.svg?react";
import { toYYYYMMDD } from "@/lib/utils";
import { PLACEHOLDER_TEXT } from "@/constants/text";
import { useIsPC } from "@/stores";
import { getMoneyText } from "@/utils/money";
import ArrowRight from "@/assets/icons/arrow_right.svg?react";
import { type CostDetail } from "$/api_out/http_resv/room/room_type";

interface CheckInOutProps {
  startDate?: string;
  endDate?: string;
  adultCount?: number;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  cost?: CostDetail;
}

export const CheckInOutCard: FC<CheckInOutProps> = ({
  className,
  startDate,
  endDate,
  adultCount,
  cost,
  children,
  onClick,
}) => {
  const isPC = useIsPC();

  const nights = dayjs(endDate).diff(startDate, "day");

  if (!startDate || !endDate) {
    return null;
  }

  return (
    <Card
      className={clsx(
        {
          "p-6": isPC,
        },
        className,
      )}
    >
      <div
        className={clsx(
          "flex justify-between  border-dashed border-[#E5E5E5] pb-3",
          {
            "border-b": !isPC,
          },
        )}
      >
        <div className="flex flex-col items-start space-y-1.5">
          <span className="text-text-666 text-xs">入住日期</span>
          <span className="text-sm font-medium">
            {startDate ? toYYYYMMDD(startDate) : PLACEHOLDER_TEXT}
          </span>
        </div>

        <div className="flex flex-1 flex-col items-center space-y-1.5">
          <div className="flex items-center space-x-1">
            <PeopleIcon className="size-4" />
            <span className="text-text-3 text-xs">{adultCount}人</span>
          </div>

          <div className="border-easbnb-brand text-easbnb-brand rounded-full border border-solid bg-white px-3 py-1 text-xs">
            共{isNaN(nights) ? PLACEHOLDER_TEXT : nights}晚
          </div>
        </div>

        <div className="flex flex-col items-end space-y-1.5">
          <span className="text-text-666 text-xs">退房日期</span>
          <span className="text-sm font-medium">
            {endDate ? toYYYYMMDD(endDate) : PLACEHOLDER_TEXT}
          </span>
        </div>
      </div>
      {!isPC && cost ? (
        <div className="mt-3 flex items-center justify-between text-sm">
          <div className="text-text-666">
            <span>
              {getMoneyText(cost.payAmount, cost.currency, {
                hidePlusSign: true,
              })}
            </span>

            {cost.refundAmount ? (
              <span className="ml-2">
                (退款
                {getMoneyText(cost.refundAmount, cost.currency, {
                  hidePlusSign: true,
                  hideLabel: true,
                })}
                )
              </span>
            ) : null}
          </div>
          <div
            onClick={onClick}
            className="text-easbnb-brand flex items-center"
          >
            <span className="mr-2">费用明细</span>
            <ArrowRight />
          </div>
        </div>
      ) : null}
      {children}
    </Card>
  );
};
