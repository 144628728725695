/** @format */

// This is code generated automatically by the proto2api, please do not modify

import { webapi } from "$/utils/webapi";
import { type User } from "../user/user_type";
import { type Tenant } from "../common/common_type";

export namespace SignService {
  // 通过邮箱登录
  export function SignInByEmail(req: SignInByEmailReq, config?: any) {
    return webapi.post<SignInByEmailResp>(
      "/api/v1/SignService_SignInByEmail",
      req,
      config,
    );
  }

  // 账号密码登录
  export function SignInByAccount(req: SignInByAccountReq, config?: any) {
    return webapi.post<SignInByAccountResp>(
      "/api/v1/SignService_SignInByAccount",
      req,
      config,
    );
  }

  export function Login(req: LoginReq, config?: any) {
    return webapi.post<LoginResp>("/api/v1/SignService_Login", req, config);
  }

  // 发送邮件
  export function SendEmail(req: SendEmailReq, config?: any) {
    return webapi.post<SendEmailResp>(
      "/api/v1/SignService_SendEmail",
      req,
      config,
    );
  }

  // 登出
  export function SignOut(req: SignOutReq, config?: any) {
    return webapi.post<SignOutResp>("/api/v1/SignService_SignOut", req, config);
  }
}
// SignInByEmail
export interface SignInByEmailReq {
  // 邮箱
  email: string;
  // 验证码
  code: string;
}

export interface SignInByEmailResp {}

// SignInByAccount
export interface SignInByAccountReq {
  // 账号
  account: string;
  // 密码
  password: string;
}

export interface SignInByAccountResp {}

// Login
export interface LoginReq {
  autoLoginKind?: AutoLoginKind;
  autoLoginCode?: string;
}

export interface LoginResp {
  user: User;
  defaultTenant?: Tenant;
}

export interface SendEmailReq {
  // 邮箱
  email: string;
}

export interface SendEmailResp {}

// SignOut
export interface SignOutReq {}

export interface SignOutResp {}
export enum AutoLoginKind {
  AutoLoginKindUnknown = 0,
  // 回调登录
  SsoToken = 1,
  Wechat = 10,
  // 第三方 外部系统
  External = 101,
}
