import clsx from "clsx";
import { type RoomInDetail } from "$/api_out/http_resv/room/room_type";
import { Card, LodgingDates, TenantCount } from "@/components";
import { useIsPC } from "@/stores";
import AlertIcon from "@/assets/icons/alert.svg?react";
import { useResvInfo } from "@/hooks";

interface BookingCardProps {
  roomId: string | undefined;
  changeAdultCb: () => void;
  data: RoomInDetail;
  maxAdultCount?: number;
  children?: React.ReactNode;
  className?: string;
}

export const BookingCard: React.FC<BookingCardProps> = ({
  data,
  roomId,
  changeAdultCb,
  children,
  className,
  maxAdultCount,
}) => {
  const isPC = useIsPC();
  const { occupancy, setOccupancy, adultCount, setAdultCount } = useResvInfo();

  if (!data?.room.isOnSale) {
    return null;
  }

  return (
    <Card
      className={clsx(
        {
          "p-4": isPC,
        },
        className,
      )}
    >
      {!occupancy ? (
        <div className="mx-[-4px] mb-1 flex h-7 items-center rounded-full border border-[#FDF6EF] bg-[#FDF6EF]">
          <AlertIcon className="mx-2 my-1.5 size-4" />
          <span className="text-xs text-[#EC642B]">
            请选择预定日期和入住人数。
          </span>
        </div>
      ) : null}
      {occupancy && !data.canBook ? (
        <div className="mx-[-4px] mb-1 flex h-7 items-center rounded-full border border-[#FDF6EF] bg-[#FDF6EF]">
          <AlertIcon className="mx-2 my-1.5 size-4" />
          <span className="text-xs text-[#EC642B]">
            该日期已经被预定，请重新选择。
          </span>
        </div>
      ) : null}

      {maxAdultCount && adultCount > maxAdultCount ? (
        <div className="mx-[-4px] mb-1 flex h-7 items-center rounded-full border border-[#FDF6EF] bg-[#FDF6EF]">
          <AlertIcon className="mx-2 my-1.5 size-4" />
          <span className="text-xs text-[#EC642B]">
            该房间最多可住{maxAdultCount}人，请调整人数。
          </span>
        </div>
      ) : null}
      <LodgingDates roomId={roomId} value={occupancy} onChange={setOccupancy} />
      <TenantCount
        maxValue={data?.room.personCapacity}
        value={adultCount}
        onChange={(count) => {
          changeAdultCb();
          setAdultCount(count);
        }}
        className={clsx({
          "border-b": isPC,
        })}
      />
      {children}
    </Card>
  );
};
