import React from "react";
import { Image } from "react-vant";
import clsx from "clsx";
import type * as room_type from "$/api_out/http_resv/room/room_type";
import LocationIcon from "@/assets/icons/location.svg?react";
import { Card } from "@/components/Card";
import { PLACEHOLDER_TEXT } from "@/constants/text";
import { getMoneyText } from "@/utils/money";
import { getRoomInfo } from "@/utils/roomInfo";
import { PATHS } from "@/constants/path";
import { useCustomNavigate } from "@/hooks";
import { type CurrencyAmount } from "$/api_out/http_resv/common/common_type";

interface DetailRoomCardProps {
  disableLink?: boolean;
  imageUri: string;
  room: room_type.Room;
  price?: CurrencyAmount;
  orderId?: string;
}

export const DetailRoomCard: React.FC<DetailRoomCardProps> = ({
  disableLink = false,
  room,
  price,
  imageUri,
  orderId,
  ...props
}) => {
  const roomInfoText = getRoomInfo({
    room,
  });
  const customNavigate = useCustomNavigate();

  const onGoToRoomDetail = (e: React.MouseEvent) => {
    if (disableLink) return;
    customNavigate(PATHS.ROOM, {
      id: room.id,
      state: {
        backPath: PATHS.ORDER,
        orderId,
      },
    });
    e.stopPropagation();
  };

  return (
    <Card className="max-w-screen-lg px-6" {...props}>
      <div
        className={clsx("flex py-3", {
          "cursor-pointer": !disableLink,
        })}
        onClick={onGoToRoomDetail}
      >
        <div className="mr-4 h-[160px] w-[200px] overflow-hidden rounded-lg">
          <Image src={imageUri} alt="房间图片" fit="cover" />
        </div>
        <div className="flex flex-1 flex-col">
          <div className="text-text-1 mb-3 line-clamp-1 text-ellipsis text-xl font-medium">
            {room.title || PLACEHOLDER_TEXT}
          </div>
          <div className="mb-8">
            {room.roomSize > 0 && (
              <span className="text-text-666 mr-3 text-sm">{roomInfoText}</span>
            )}
            {room.personCapacity > 0 && (
              <span className="text-easbnb-brand text-sm">
                可住{room.personCapacity}人
              </span>
            )}
          </div>
          <span className="text-text-2 mb-5 text-sm">
            {price ? (
              <div>
                {getMoneyText(price.amount, price.currency, {
                  hidePlusSign: true,
                })}
              </div>
            ) : null}
          </span>

          {room.address && (
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-2">
                <LocationIcon className="size-4" />
                <div className="text-text-666 text-sm">{room.address}</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
