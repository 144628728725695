import clsx from "clsx";
import { type RoomInDetail } from "$/api_out/http_resv/room/room_type";
import { Card } from "@/components";
import { useIsPC } from "@/stores";

interface RoomPresentCardProps {
  data: RoomInDetail;
}

export const RoomPresentCard: React.FC<RoomPresentCardProps> = ({ data }) => {
  const isPC = useIsPC();

  if (!data?.introduction) {
    return null;
  }

  return (
    <Card
      title="房间介绍"
      className={clsx(
        {
          "p-6": isPC,
        },
        "max-w-screen-lg ",
      )}
    >
      <div className="text-text-666 h-full whitespace-pre-line break-all pt-3 text-sm">
        {data?.introduction}
      </div>
    </Card>
  );
};
