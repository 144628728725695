import clsx from "clsx";
import { type FC } from "react";
import { type Discount } from "$/api_out/http_resv/room/room_type";
import { DISCOUNT_MAP } from "@/constants/options";

export interface RentDiscountProps {
  discounts: Discount[];
  className?: string;
}

export const RentDiscount: FC<RentDiscountProps> = ({
  discounts,
  className,
}) => {
  if (discounts.length === 0) return null;

  return (
    <div
      className={clsx(
        "mt-2 inline-flex h-5 w-fit items-center justify-center gap-x-3 rounded bg-gradient-to-r from-[#EC642B33] to-[#EC642B0D] px-2 text-xs text-[#F74D36]",
        className,
      )}
    >
      {discounts.map((discount) => (
        <span key={discount.kind}>
          {DISCOUNT_MAP[discount.kind].label} -{discount.percentage / 100}%
        </span>
      ))}
    </div>
  );
};
