import clsx from "clsx";
import { type RoomInDetail } from "$/api_out/http_resv/room/room_type";
import LocationIcon from "@/assets/icons/location.svg?react";
import SubwayIcon from "@/assets/icons/subway.svg?react";
import { Card } from "@/components";
import { useIsPC } from "@/stores";

interface TrafficMethodCardProps {
  data: RoomInDetail;
}

export const TrafficMethodCard: React.FC<TrafficMethodCardProps> = ({
  data,
}) => {
  const isPC = useIsPC();

  if (!data?.room.address && !data?.room.traffic) {
    return null;
  }

  return (
    <Card
      title="交通方式"
      className={clsx({
        "p-6": isPC,
      })}
    >
      {data?.room.address && (
        <div className="my-3 flex items-start">
          <LocationIcon className="mr-1 mt-0.5 size-4" />
          <div className="text-text-666 text-sm">{data?.room.address}</div>
        </div>
      )}
      {data?.room.traffic && (
        <div className="mb-3 flex items-start">
          <SubwayIcon className="mr-1 mt-0.5 size-4" />
          <div className="text-text-666 flex-1 whitespace-pre-line text-sm">
            {data?.room.traffic}
          </div>
        </div>
      )}
    </Card>
  );
};
