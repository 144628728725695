import clsx from "clsx";
import { type OrderInDetail } from "$/api_out/http_resv/order/order_type";
import { Card } from "@/components";
import { useIsPC } from "@/stores";

interface PersonNoticeCardProps {
  data: OrderInDetail;
}

export const PersonNoticeCard: React.FC<PersonNoticeCardProps> = ({ data }) => {
  const isPC = useIsPC();

  if (data?.hidePaidMessageCard) {
    return null;
  }

  return (
    <Card
      className={clsx({
        "p-6": isPC,
      })}
      title="房客须知"
    >
      {data?.hidePaidMessageText ? (
        <div className="mt-3 rounded-lg bg-[#F5F7FB] py-2 text-center">
          <span className="text-text-666 whitespace-pre-line  text-sm">
            付款后可查看房客须知
          </span>
        </div>
      ) : (
        <div className="text-text-666 whitespace-pre-line pt-3 text-sm">
          {data?.paidMessage}
        </div>
      )}
    </Card>
  );
};
